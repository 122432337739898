<template>
<!-- 全局提⽰框 -->
    <div v-show="is_show" :class="{ 
        open: visible,
        close: !visible,
        dialogTop:position=='' || position=='top',
        dialogCenter:position=='center',
        dialogBottom:position=='bottom',
    }" class="dialog-tips flex_center">
        <div class="box" :class="{
            message:type=='',
            error:type=='error',
            success:type=='success',
        }">{{message}}</div>
    </div>
</template>
<script>
export default {
  data() {
return {
      visible: false,
      is_show: false,
      message: "",
      type: "",
      position: ""
    };
  }
};
</script>
<style lang="scss" scoped>
.dialog-tips{
    position: fixed;
    z-index: 9999;
    white-space: normal;
    word-break: break-all;
}
.box{
    min-width: 120px;
    max-width: 220px;
    box-shadow: 0px 8px 15px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 3px;
    padding: 8px;
}
.message{
    background-color: rgba(#ffffff,0.9);
    color: #000000;
    border: 1px solid #c5c5c5;
}
.error{
    background-color: rgba(#fef0f0,0.9);
    color: #f56c6c;
    border: 1px solid #fabebe;
}
.success{
    background-color: rgba(#f0f9eb,0.9);
    color: #62b637;
    border: 1px solid #a1bb94;
}
.open {
    animation: open 0.3s normal forwards;
}
.dialogTop {
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
}
.dialogCenter {
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
}
.dialogBottom {
    bottom: 20%;
    left: 0;
    right: 0;
    margin: auto;
}
@keyframes open {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
// 关
.close {
    animation: close 0.3s normal forwards;
}
@keyframes close {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>