import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import './assets/fonts/iconfont.css'
import './assets/iconfont/iconfont.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Head from "@/components/Head";
Vue.component('Head', Head)
// 複製
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.prototype.$copy = function copy(address){
  this.$copyText(address).then(e=>{
    this.$toast(this.$t('tips.title2'))
  }, e=> {
    this.$toast(this.$t('tips.title3'))
  })
}
Vue.prototype.toFixedWei = function (count,num=2){
  return Number(parseFloat(count).toFixed(num))
}

Vue.prototype.errorToast = function(e){
  let msg='请修改容差滑点后重试'
  if(e.message.indexOf('INSUFFICIENT_')<0){
    if(e.message.indexOf('User denied transaction signature')>=0){
      msg='取消发送'
    }else{
      msg=e.message.replace('Internal JSON-RPC error.','')
      try{
        let obj=JSON.parse(msg)
        msg=obj.message
      }catch(e){
        
      }
      this.$message({
        type:'error',
        message: msg
      })
    }
  } 
  this.$toast(msg)
}

import { Loading } from 'element-ui';
import walletHelper from "@/utils/walletHelper.js"
var loading

Vue.prototype.sendWeb3 = function(obj,loadmsg='交易正在处理中'){
  const that=this
  return new Promise(function (resolve, reject) {
    loading =Loading.service({
      lock: true,
      text: loadmsg,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    obj.estimateGas({ from: walletHelper.getAddress()})
    .then(gas=>{
      console.log('gas',gas)
      obj.send({from:walletHelper.getAddress(),gas:gas}).then((result)=>{
        loading&&loading.close();
        resolve&&resolve(result)
      },e=>{
        loading&&loading.close();
        console.log(e)
        that.errorToast(e)
        reject&&reject(e)
      })
    },e => {
      loading&&loading.close();
      console.log(e)
      that.errorToast(e)
      throw e;
    })
  })
  
}

// 多语言
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale:(function(){
    if(localStorage.getItem('language')){
      return localStorage.getItem('language')
    }
    return 'en'
  }()),
  messages:{
    'en':require('./language/en'), //英文语言包
    'cn':require('./language/cn'), //中文繁体包
    'jpn':require('./language/jpn'), //日語繁体包
    'kr':require('./language/kr'), //韓語繁体包
  }
})

// 轮播
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(vueSwiper)
// 提示框
import Toast from '@/components/toast/toast'
Vue.prototype.$toast = Toast
// 消息
import information from '@/components/information'
Vue.component('information',information)
// 跳转
Vue.prototype.$toPage = function(url){
  this.$router.push(url)
}

var setWidth = ''
let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
if(flag) {
  console.log("移动端");
  setWidth = '100%'
}else {  
  console.log("pc端"); 
  setWidth = '600px'
}
Vue.prototype.$setWidth = setWidth

import { request, get ,getTimestamp} from '@/utils/request'

// Vue.prototype.$msg = msg
Vue.prototype.request = request
Vue.prototype.get = get
Vue.prototype.getTimestamp = getTimestamp

Vue.prototype.$onLaunched = new Promise(resolve => {
  Vue.prototype.$isResolve = resolve
})

Vue.prototype.bk = function() {
  router.go(-1)
}

Vue.config.productionTip = false

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
})
