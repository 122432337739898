import Web3 from 'web3'
import store from '@/store'
// import { generateAddress } from 'ethereumjs-util';
// import { isInt16Array } from 'util/types';
import { get } from './request'
import Toast from '@/components/toast/toast'
var abi_buy =require('/token.json')
var abi_nft =require('/token_nft.json')
var abi_erc20 =require('/token_erc20.json')
import { Loading } from 'element-ui';
const {aggregate} = require('./multicall.js');

//main主链上测试用的
// var contract_buy_address="0x1d99cE6F23fE6C704785c2109C834a78cEe9ccEE";
// var contract_nft_address="0x578EaCb8d7Da5Cbc8CE5CF19A5cE3d87B3c48E93";
// var contract_usdt_address="0x55d398326f99059fF775485246999027B3197955";
// var contract_mt_address="0x02C39aC5328DE65fc38d437615fe1389848d0eA0";

//PancakeSwap: Router v2 
//0x10ED43C718714eb63d5aA57B78B54704E256024E

//发币地址
//0x1E1B3F213c6eDB987a5211E550E8a859666CAbc6

//_roter=0x7995aA576C782Bf73A6643603637085c641497D8;
var contract_address={
  97:{
    // dao:'0xd12e90577518CebE333fD4a7D72B533479480D11',//9-27 测试gas
    // daoV1:'0xf77D0200fFe249C537E5AdcbfA1235FaB15EC99f',
    // dao:'0x864dbFB6a6ABaa4Da1613a700082479CB2c4c836',//9-28 测试
    // daoV1:'0x3e7E9C2F83B1C771Ef28F74DBb5372c070645156',

    buy:'0x4800cfe695873dF13936ee411c7E2C7FFEde4805',
    nft:'0x7B15001Cf26f08da91beaEA26009F25baFa123e7',
    usdt:'0x170D6124E35Ae14f5092eeB997a129fd672970eC',
    query:'0x901A65E215F73520B47612C10af2107CB09586EA',
    dis:'0x6FD42216182319c7312a9C1BFf8D71601E0136f2',
    disOld:'0x8fD134D3523CaE937f81E1E1359a12Cc1aE3AE59',
    mt:'0x047db4B9c344670FB311776557D66Fe61178c170',
    ulm:'0xEBbde30D88B1BDc00AE1F6afb63C65b30D77Cc10',
    // dao:'0xE265977657eDD77FacAB69e2b9AFFd350C2d59E7',
    dao:'0x290237445d68B8CDE187a89291eaF7Dac64ecaAA',
    // daoV1:'0xDfFd88247415afb20D5dF6513FCB16F5948355ae',
    daoV1:'0x0a0a8F1e587951C689ff7cbb830b95FB8E7599b2',
    daoV2:'0x1c4Fe4dDD9fa357a845F671C95fAa58168e2A581',
    daoV2New:'0xccd3f977AbC18eA3e7F56E93dE0Edd99D690C982',
    daoV2Old:'0x6b3de709fdcff59eb85059f8d727d157bffd51cb',
    daoV3:'0x9b79F178fE3597C1a2f3da3b0B4142d75F33C40d',
    // daoV4:'0xA9EED6b3ee78F14aaF3aEa02B5b5033a006de6c3',
    daoV4:'0x57054C990c16Ca02cF1625CdE98A9acb7b9Db3be',
    ulmr:'0x0B919bF0e72509bc53Bd44c79b22F614D86526EF',
    mtr:'0x823E73b6DC02CB05eD227e9948c1b81E957fF3E6',
    
    // dao:'0x0dD46a9908B5C960F0EA0996d7b91CB2Ea9DE7F2',
    // dao:'0x42E1709630FEB7ECb85583548e9f4585bd5Abb8B',
    // dao:'0xC521Ae508e0792F185bFa2C83fF4a92960d30604',
    // dao:'0x11dE4Cad4EBbfbC038f49E250c8E96A365e0AB1C',
    // dao:'0xC54984579e497Ff3f2ED29B535161Adb9577ed4E',
    rel:'0xd57D6eAD43132935d995F55b47a4929f959c9191',
    // rel:'0xd7191B1a9F76CDC5C3204dcF67B923F877849b2A',
    cc67:'0x6b5497Ea219a4C5Ba9d1E9a287D9b1c1fc82036B',
    cc88:'0x94613224e7A7CB2eb1d27909E530f4658436f29f',
    cc99:'0x4d47c944cDaff8193Bdb2084749A1d6e34b070BC',
    dd80:'0xBaea2AA05c96249557B283819742A0a8422F7cA1',
    send80:'0x17FbecBF2df7b4Cd4614dC9dF5E4bfECd8A9f9aA',
    daoV2Newbj:'0x6F375A9ae632f09c1e74c43a072E7b7Fe10dc35d',
    dao390:'0xb99abC20ca17429bF1D1b460A86e6e983bFF9C12',
    daoV1h:'0xE11c1E4382aebAFd54071f268D809606c73F3223',
    // dao390New:'0xd08f5384B4dce7B8347f9758a219AEbF4Ec9EFb3',
    dao390New:'0x23467372d035aAdD9B1dF44F8a4100AB86770f02',
    dao390Take:'0xCA63705f6F13fe6F633F7dFb03A291aB209A54E9',
    mt180:'0x1cAE3f274864710ca3D8a42A383EC297DaE2CbEd',

  },
  56:{
    buy:'0x53969f1917B1A449cA78Ad305E7720DAf7326B9e',
    nft:'0xB80Ed8EB2354D934aF6d4575263654913bC0094A',
    usdt:'0x55d398326f99059fF775485246999027B3197955',
    query:'0x2a22a1Fe7764646fE97dc06C3F8A543f5d2563F5',
    dis:'0x096b8f93a642dBb5E8162d9D618FAc2CEA6f2C2d',
    disOld:'0x67Ce3E5426357F80BA66C4Bc878B21789ebf88B0',
    mt:'0x2f3f25046Ea518d1E524B8fB6147c656D6722CeD',
    ulm:'0xAE975a25646E6eB859615d0A147B909c13D31FEd',
    ulmOld:'0x098Fcc3B3aaF02108aa00C93F069e0783b995390',
    dao:'0x17eaAC45138796e9c70850B2f556dF2aCA246DcB',//1
    daoOld1:'0x7a790dF354926a401329Bc606F67ED6674435744',//1
    daoOld:'0xFaC064847aB0Bb7ac9F30a1397BebcEdD4879841',//1
    daoV1:'0xD23186Ff35c3972B925E137c27cDfAC5570c0d2c',//1
    daoV2:'0x2CA07846Fd846B47C577930D08dE5F3Bd1559BA2',//1,3
    daoV2bj:'0x5B2dD1756A5a62AC95D7c6A4a449FF2F77D5653E',//1,3
    daoV2New:'0xB56b5Cd919E2Ab61197EDd1Dbc222554a4c87e58',//1,3
    daoV2Old:'0x34Bf878a74C758b77BCe2AcD485cFAbA0e368F0A',//1
    daoV3:'0x63e50FB5318d091109aA74aA6F9D1b3986812A60',//2
    daoV4:'0x02125f69656B1e250c38bb257B7343A688Ab33b7',
    // daoV4:'0xEc4d2dA5c37f3D115cfd500a9AaBDCddba61561c',上一个V4 updateTeam
    daoV4h:'0x5f7bd9b34c4cE7D182C598B7580F07DA5204A1C0',
    daoV4to67:'0xC6a3601aBE06cEa87083EFc4e5F6e387478398A6',
    daoV3Old:'0x01e853D43a5b11b268bd512273d3dcE789F0af39',//1
    rel:'0x8b81D1F9DED0976f40bdDD957D48fe18bfd6C901',
    cc67:'0xa0f76967e9F36367c9045AdcfEe0F62D17B4F016',
    cc67Old:'0xCb4822f0aaD7d573CCa638a6a8139a42246D6531',
    cc88:'0x4EC7dA79B5B9e68B02aA87cB9fa5eD9fF270182E',
    cc99:'0xa37435f1CeF6B013440EFC6C338dCE5974bF23dD',
    dd80:'0xb4Bc59EE3860516e73fe648F35210a0193d29113',
    dd10000:'0x25812c28CBC971F7079879a62AaCBC93936784A2',
    disNew3:'0x1617f2925700714ffC0e7e4c0731Ba997a3469dF',
    ulmr:'0x06b462F6aD7f473d245380b466b572D7413b9Bb4',
    mtr:'0x9A7A2aC4c954CD77d4b72Ee28B0EEEf743B1625f',
    copyV1:'0x7C7238319250B30eaFa64E1EFE9415c4eC0aee46',
    daoV2Newbj:'0x6F375A9ae632f09c1e74c43a072E7b7Fe10dc35d',
    daoV1h:'0x06FDE3dA013bD1a7D99F2800b8C80e69D6F0b05b',
    dao390:'0xf2261b12aADe602f5062132aE679CCAc1021dF75',
    dao390New:'0x9cEe7c0838077cda776B0e4042BEC8D8DB33B448',
    // dao390Take:'0xCd0DDa826c4e83dA471BbC9b3d420eb478B546Ec',
    dao390Take:'0xC6AaaA4cC207882576939E3E223De18ffd827834',
    mt180:'0xABEd6DEcF03Fc81f85de108Bc59a826D76F6023f',

  },
}

var dataIndex={
  _regenerate_power:"1",
  _take_power:"2",
  _power:"3",
  _powerTeam:"4",
  _powerTeamMax:"5",
  _userLevel:"6",
  _powerReceived:"7",
  _minerCount:"8",
  _parentReward:"9",
  _userLastTime:"10",
  _branchTime:"11",
  _userLpTime:"12",
  _userLpMt:"13",
  _userLpUlm:"14",
  _userLpPledgeMt:"15",
  _userLpPledgeUlm:"16",
  _userLargeWithdrawal:"17",
  _parentReward_v4:"18",
  _userPledgeMt:"19",
  _userPledgeUlm:"20",
  _userNewLpMt:"22",
  _userNewLpUlm:"23",
}

var contract_obj={
  97:{},
  56:{}
}
//test

var contract_buy
var contract_nft
var contract_usdt
var web3=undefined
var chainId=97
var init_pwd=0
init_wallet()

export function getChainId(){
  return chainId
}
export function init_wallet(){

  if (typeof window.ethereum !== 'undefined'|| (typeof window.web3 !== 'undefined')) {
    // 检测到Web3浏览器用户。 现在可以使用提供程序了。
    const provider = window['ethereum'] || window.web3.currentProvider;
    chainId=Number(window['ethereum'].chainId)
    // 实例化web3
    web3 = new Web3(provider);
    // window.web3 = web3;//挂载在window上，方便直接获取
  if(chainId==0)chainId=56
  console.log('chainId',chainId,window['ethereum'])

  web3.eth.getGasPrice().then(price=>{
		console.log('web3对象2222price',price)
	},e=>{
		console.log('web3对象2222',e)
	})
    contract_buy = new web3.eth.Contract(abi_buy, contract_address[chainId].buy);
    contract_nft = new web3.eth.Contract(abi_nft, contract_address[chainId].nft);
    contract_usdt = new web3.eth.Contract(abi_erc20, contract_address[chainId].usdt);
  }else{

    // Toast('无 window.ethereum')
    setTimeout(()=>{
      connectMetamask()
    },5000)
  }
  // multicallHelper.init()
}

export function getContract(field,token) {
  if(contract_obj[chainId][field]==undefined||token!=undefined){
    let abi=field
    if(field.indexOf('cc')==0){
      abi='community'
    }else if(field.indexOf('dd')==0){
      abi='DistrbutionToken'
    }else if(field=='send80'){
      abi='SendToken'
    }

    if(token==undefined){
      token=contract_address[chainId][field]
    }else{
      return new web3.eth.Contract(require('/abi/'+abi+'.json'), token);
    }
    contract_obj[chainId][field]=new web3.eth.Contract(require('/abi/'+abi+'.json'), token);
  }
  return contract_obj[chainId][field]
}
export function getWeb3() {
  return web3
}
export function add(a,b) {

  // return web3.utils.toWei(String(Number(web3.utils.fromWei(a))+Number(web3.utils.fromWei(b))))

  var BN = web3.utils.BN;

  let x=new BN(a)
  let y=new BN(b)
  return x.add(y).toString()
}
export function isNotTranfer() {
  return false
  return chainId==56
}

export function getUserData(user,fun) {
  var loading =Loading.service({
    lock: true,
    text: '正在查询中',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
});
  getContract('daoV1').methods.getData(user).call().then(e=>{
    console.log(user)
    console.log(e)

    let data={
      regenerate_power:Wei(e[1]),//再生(USDT)
      take_power:Wei(e[2]),//所有已领取
      power:Wei(e[3]),
      powerTeam:e[4],//团
      powerTeamMax:e[5],//团
      userLevel:e[6],
      powerReceived:Wei(e[7]),//已领
      minerCount:e[8],
      parentReward:Wei(e[9]),//布道奖励(MT)
      userLastTime:e[10],
      branchTime:e[11],
      userLpTime:e[12],
      userLpMt:Wei(e[13]),
      userLpUlm:Wei(e[14]),
      userLpPledgeMt:Wei(e[15]),
      userLpPledgeUlm:Wei(e[16]),
      userLargeWithdrawal:e[17],
      parentReward_v4:Wei(e[18]),//布道奖励(ULME)
      userPledgeMt:Wei(e[19]),
      userPledgeUlm:Wei(e[20]),
      userNewLpMt:Wei(e[22]),
      userNewLpUlm:Wei(e[23]),
      _minerCount_390:e[28],
      _regenerate_power_390:Wei(e[29]),
      _userSurplus_390:e[30],
      _userTrendsPower_390:Wei(e[31]),
      _userTrendsSurplus_390:Wei(e[32]),
      _userTrendsTime_390:e[33],
      _userListTime_390:e[34],
      info:e,
    }
    loading.close()
    // console.log(data)
    fun(data)
  },e=>{
    console.log(e)
    fun(null,e)
  })
}
export function ERC20(token) {
  return new web3.eth.Contract(abi_erc20, token);
}
export function getContractAddress(field) {
  return contract_address[chainId][field]
}
export function getBuyContract() {
  return contract_address[chainId].buy
}
export function getNFTcontract() {
  return contract_address[chainId].nft
}
export function getNFT() {
  return contract_nft
}
export function getBuy() {
  return contract_buy
}
export function getUsdt() {
  return contract_usdt
}
export function fromWei(num) {
  return Number(num)==0?0:web3.utils.fromWei(num)
}
export function Wei(num) {
  return num==undefined||Number(num)==0?0:Number(parseFloat(web3.utils.fromWei(num)).toFixed(6))
}
export function toWei(num) {
  return web3.utils.toWei(num)
}

export function getLevelconfig() {
  getContract('daoV1h').methods.getLevelconfig().call().then((result)=>{
    let data=[]
    
    for(let j=0;j<25;j+=8){
      let i=j
      if(i>16)i=16
      data.push({
        count:Number(result[i]),ng:Number(result[i+1]),power:Wei(result[i+2]),mt_lp:Wei(result[i+3]),ulm_lp:Wei(result[i+4]),
        team:Number(result[i+5]),mt_pledge:Wei(result[i+6]),ulm_pledge:Wei(result[i+7])
      })
    }
    // console.log(data)
    store.commit('user/SET_LEVELCONFIG', data)

  })

}

export async function connectMetamask() {
  if(web3==undefined){
    init_wallet()
  }

  if(web3!=undefined){
    console.log('chainId111')

    let res=await window.ethereum.enable()
    // if(init_pwd<2){
      // init_pwd++
      chainId=Number(window.ethereum.chainId)
      console.log('chainId',chainId,res)
      try{
        if (!process.env.VUE_APP_DEBUG) {
          // let data=await web3.eth.sign(web3.utils.keccak256("Hello world"), res[0])
        }
        // init_pwd++
        var parent_address = store.state.user.parent_address
        bind(res[0],parent_address)
        store.commit('user/SET_PARENT_ADDRESS', '')
        store.commit('user/SET_WALLET_ADDRESS', res[0])
      }catch(e){
        console.log(1111111111222222222)
        Toast(JSON.stringify(e))
        await connectMetamask()
        return ''
      }
      // console.log(1111111111222222222,res.length>0?res[0]:'')

    // }
    return res.length>0?res[0]:''
  }else {
    // alert("请安装MetaMask钱包")
    return ''
  }
}

var isRun=false
export function bind(add,parent){
  // console.log(add,parent)
  if(isRun)return
  isRun=true
  if(store.state.user.userInfo.id)return
  // if(window&&window.localStorage.getItem(add)==undefined && add.slice(0,2)=='0x' && parent.slice(0,2)=='0x' && parent!=add){
    get('/api/Community/binding', {wallet:add,referrer:parent}, 'POST').then(res => {
      // window.localStorage.setItem(add,0)
      store.commit('user/SET_IS_BIND', false)
      if(res!=undefined){
        store.commit('user/SET_USER_INFO', res.info)
        store.commit('user/SET_TOKEN', res.token)
        
      }
      isRun=false
    },err=>{
      store.commit('user/SET_IS_BIND', true)
      Toast({title:'您的网络不稳定'})
      isRun=false
    })
  // }
}
export function send(){

  contract_buy.methods.say().send({
    from: getAddress()
  },(error, transactionHash) => {
    console.log('返回',transactionHash);
  })
}

export function getAddress(){
  // return '0x6330854C5df025C32746fF8B0347f7fD43661bA2';
  return store.state.user.wallet_address;
}
export async function getAccount(){
  let accounts = await web3.eth.getAccounts();
  console.log("返回节点所控制的账户列表", accounts);
  // 取第一个账户
  let myAccount = accounts[0];
  return myAccount;
}
export function call(){

  //methods.myMethod.call 将在不发送交易的情况下调用该“常量”方法并在 EVM 中执行其智能合约方法。注意此种调用方式无法改变智能合约状态。
  contract_buy.methods.say().call({from: getAddress()}, function(error, result){
    console.log('返回',result);
  });

}
export function getTimeOut(index) {
  let ls=['0x5E344DaA64aBA91a82c458A4Ae920616dE5A109d','0xC232Ab0F2a94256f3F7ffC6C10C293cd5d2ceB4a']
  if(contract_obj[chainId][index]==undefined){
    contract_obj[chainId][index]= new web3.eth.Contract(require('/abi/timeout.json'), ls[index]);
  }
  return contract_obj[chainId][index]
  
}

export function clickAddress() {
  // let address=getAddress()
  // let list=require('/address_password.json')
  // console.log(list)
  // for(let i  in list){
  //   if(address.toLowerCase()==list[i].toLowerCase()){
  //     return true
  //   }
  // }
  // return false
}

const prepareConfig={
  multicallAddress: "0xcA11bde05977b3631167028862bE2a173976CA11",
  web3: web3,
}

//type 0对象 1数组 2 直接
export function multicall(data,type=0){

  if(type==2){
    return aggregate(data,prepareConfig)
  }
    return new Promise(function (resolve, reject) {
      aggregate(data,prepareConfig).then(updates=>{
        // console.log(updates)
        const res=[]
        if(type==1){
          for (const item of updates) {
            if(item.type!=undefined){
              let ls=item.type.split(',')
              if(ls.length>1){
                let index=Number(ls[1])
                if(res[index]==undefined){
                  res[index]={index:index}
                }
                res[index][ls[0]]=item.value
              }
            }
            
          }
        }else{
          for(let value of updates){
            if(value.type!='undefined'){
                res[value.type]=value.value
            }
          }
        }
        resolve(res)
      },reject)
    })
  
}

export default {
  connectMetamask,
  getNFTcontract,
  getBuyContract,
  init_wallet,
  getNFT,
  getBuy,
  getUsdt,
  Wei,
  fromWei,
  toWei,
  bind,
  getWeb3,
  ERC20,
  getAddress,
  getContract,
  getContractAddress,
  getChainId,
  dataIndex,
  getUserData,
  isNotTranfer,
  getTimeOut,
  clickAddress,
  getLevelconfig,
  add,
  multicall,
}

