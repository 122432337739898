<template>
  <div id="app">
    <router-view />
	<!-- 绑定 -->
    <div class="bind flex_center" v-if="show_bind" @touchmove.prevent @mousewheel.prevent>
      <div class="mask"></div>
      <div class="bind_bg">
        <div class="bind_box y_center">
          <div style="color: #00DEFF;">{{$t('index.bind_popup.title')}}</div>
          <textarea :placeholder="$t('index.bind_popup.input')" v-model="parent" />
          <button @click="show_confirm = true">{{$t('index.bind_popup.btn')}}</button>
        </div>
      </div>
    </div>
	<!-- 确认绑定 -->
	<div class="confirm flex_center" v-if="show_confirm" @touchmove.prevent @mousewheel.prevent>
		<div class="mask"></div>
		<div class="confirm_bg">
			<img src="@/image/guanyu_02.jpg" alt="">
			<div class="confirm_box y_center">
				<div>{{$t('tips.title7')}}</div>
				<div class="confirm_address">{{parent}}</div>
				<div class="flex_lr" style="width: 100%;margin:20px 0;">
					<div class="confirm_btn1 flex_center hand" @click="show_confirm = false">{{$t('tips.title9')}}</div>
					<div class="confirm_btn2 flex_center hand" @click="toBind()">{{$t('tips.title8')}}</div>
				</div>
			</div>
		</div>
	</div>

	<div v-if="show_maintain==1" class="maintain flex_center" @touchmove.prevent @mousewheel.prevent>
      <div class="mask"></div>
	  <div class="maintain_bg flex_ceneter">
	  	<div class="maintain_box">
		  <div style="font-size:1.1rem;margin-bottom:10px;text-align: center;">{{$t('tips.title5')}}</div>
		  <div>{{$t('tips.title6')}}</div>
		</div>
	  </div>
	</div>
  </div>
</template>

<script>
import walletHelper from "@/utils/walletHelper.js"

export default {
  name: 'App',
  data(){
	  return{
      	parent:'',
		show_maintain:0,
		show_bind:false,
		show_confirm:false,
	  }
  },
  components: {
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'margin: 0px;')
  },
	computed: {
		wallet_address() {
			return this.$store.state.user.wallet_address
		},
    	is_bind() {
			return this.$store.state.user.is_bind
		},
		parent_address() {
			return this.$store.state.user.parent_address
		},
	},
	watch: {
		is_bind:{
			handler (val, oldVal) {
				if(this.is_bind&&this.parent_address!=''){
					walletHelper.bind(this.wallet_address,this.parent_address)
				}else if(this.is_bind){
					this.show_bind = true
				}else{
					this.show_bind = false
				}
			},
			deep:true
		}
	},
  async mounted() {
    var type = localStorage.getItem('language')?localStorage.getItem('language'):'en'
    this.$i18n.locale = type;
	if(this.$setWidth=='100%'){
		document.getElementsByTagName('body')[0].style.setProperty('--title','18px')
		document.getElementsByTagName('body')[0].style.setProperty('--text','15px')
	}else{
		document.getElementsByTagName('body')[0].style.setProperty('--title','21px')
		document.getElementsByTagName('body')[0].style.setProperty('--text','17px')
	}

	let address=await walletHelper.connectMetamask()

	this.$isResolve()
	this.getMaintain()
  },
  methods: {
    toBind(){
      if(this.parent==''){
        return
      }
	  walletHelper.bind(this.wallet_address,this.parent)
	  this.show_confirm=false
    },
	getMaintain(){
		this.get('api/User/index', {}, 'POST').then(res => {
        	this.show_maintain = res.status
		})
	}
  },
}
</script>
<style>
	body{
		background: #000000;
	}
</style>
<style lang="scss" scoped>
.maintain{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1001;
  .maintain_bg{
    background: rgba(#00BABC,0.7);
    padding: 6px;
    border-radius: 7px;
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 300px;
    .maintain_box{
      background: rgba(#000000,0.7);
      z-index: 1000;
      padding: 15px 10px;
      border-radius: 7px;
      font-size: 16px;
	  color: #fff;
    }
  }
}
.bind{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  .bind_bg{
    background: rgba(#00BABC,0.7);
    padding: 6px;
    border-radius: 7px;
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 300px;
    .bind_box{
      background: rgba(#000000,0.7);
      z-index: 1000;
      padding: 15px 10px;
      border-radius: 7px;
      font-size: 16px;
      textarea{
        font-size: 16px;
        // width: 0;
        // flex: 1;
        width: 100%;
        padding: 15px 15px;
        background: rgba(#000000,0.7);
        border-radius: 7px;
        min-height: 66px;
        color: #00DEFF;
        line-height: 18px;
        margin: 15px 0;
      }
      // chrome
      textarea::-webkit-input-placeholder { /* WebKit browsers */
        color: rgba(#00DEFF,0.7);
        font-size:15px;
      }
      // firefox
      textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: rgba(#00DEFF,0.7);
        font-size:15px;
      }
      button{
        background: rgba(#00DEFF,0.7);
        color: #000000;
        font-size: 16px;
        border: none;
        margin-left: 10px;
        border-radius: 7px;
        height: 40px;
        // width: 80px;
        width: 100%;
      }
    }
  }
}
.confirm{
	position: fixed;
 	top: 0;
 	bottom: 0;
 	left: 0;
 	right: 0;
	margin: auto;
  	z-index: 1001;
	.confirm_bg{
		background: rgba(#051A49,1);
		border-radius: 7px;
		position: relative;
		z-index: 2;
		width: 90%;
		max-width: 300px;
		overflow: hidden;
		img{
			width: 100%;
		}
		.confirm_box{
			padding: 10px 20px;
			color: #ffffff;
			font-size: 1rem;
			.confirm_address{
				width: 100%;
				padding: 10px 20px;
				word-break: break-all;
				text-align: center;
				font-size: 0.8rem;
				color: #A1BDFF;
			}
			.confirm_btn1{
				width: 48%;
				height: 35px;
				border: 1px solid #A1BDFF;
				border-radius: 5px;
				font-size: 0.8rem;
				color: #A1BDFF;
			}
			.confirm_btn2{
				width: 45%;
				background: #173AD0;
				height: 35px;
				border-radius: 5px;
				font-size: 0.8rem;
			}
		}
	}
}
</style>
<style lang="scss">
@import './css/common.scss';
	html, body, div, ul, li, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ol, form, input, textarea, th, td, select {
		margin: 0;
		padding: 0;
		font-family: PingFang SC;
	}
	*, *:hover, *:active, ::before, ::after {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}
	* {
		box-sizing: border-box;
	}

	html, body {
		min-height: 100%;
	}

	body {
		font-family: "Microsoft YaHei";
		font-size: 14px;
		color: #333;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: normal;
	}

	ul, ol {
		list-style: none;
	}

	img {
		border: none;
		vertical-align: middle;
	}

	a {
		text-decoration: none;
		color: #232323;
	}

	table {
		border-collapse: collapse;
		table-layout: fixed;
	}

	input, textarea {
		outline: none;
		border: none;
	}

	textarea {
		resize: none;
		overflow: auto;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.common_title{
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
      	background-image: url('image/text_bg1.jpg');
     	background-size: cover;
		font-size: $title;
		font-weight: bold;
		width: 100%;
		padding: 0 15px;
		line-height: 30px;
		font-family: simhei;
	}
	.common_text{
		font-size: $text;
	}
	.center{
		margin-left: auto;
		margin-right: auto;
	}
	.y_center{
		display: flex;
		flex-direction: column;
		align-items: center;
		/* justify-content: center; */
	}
	.flex{
		display: flex;
		flex-wrap: wrap;
	}
	.flex_lr{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.flex_pj{
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.flex_ud{
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
	.flex_center{
		display: flex;
		justify-content:center;
		align-items: center;
	}
	.flex_row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.mask{
		position: fixed;
		top:0;
		left:0;
		width:100%;
		height: 100%;
		z-index:1;
		background: rgba(0, 0, 0, 0.6);
	}
	.hand{
		cursor: pointer;
		user-select: none;
	}
</style>
