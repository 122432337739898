module.exports = {
    layout:{
        lang: '語言',
        Current_language:'繁體中文',
        Home: '首頁',
        Notice: '系統公告',
        Share: '邀請分享',
        Community: '我的社區',
        Pool: 'DEFI 農場',
        fundPool:'基金池',
        Incentive: 'MT激勵基金池',
        Compensate: 'MT補償基金池',
        Destruction: '黑洞計劃',
        NFT: 'NFT交易',
        Metaverse: '元宇宙生態家園',
        Set: '系統設置',
        Problem: '矿机',
        Help: '新手幫助',
        Buy: '購買',
        LP: 'LP流動池(升级/分红)',
        Out: '退出登錄',
        About: '關於我們',
        Planning: '發展規劃',
        Vote: '提案投票',
        Pledge: '質押託管',
        PledgeMt: 'MT共識質押',
    },
    index:{
        title1:'我與世界相遇，我自與世界相蝕；',
        title2:'我自不辱使命，使我與眾生相聚！',
        title3:'那些疲乏貧困，擁擠在旅途渴望自由呼吸的人們；那些被遺棄無家可歸，飽受顛沛的人們。 我在金門旁高舉起自由的燈火！歡迎你的到來！',
        title4:'人類的幸福和歡樂在於奮鬥，而最有價值的是為理想而奮鬥！',
        title5:'做你自己財富和命運的主人，只有加密貨幣才能讓你實現財產的絕對私有化！ DAO組織集體信仰便是加密資產的覈心！',
        title6:'如果一個項目的科技賦予了它自身價值與未來，那麼共識它的DAO社區就賦予了它靈魂與生命。',
        title7:'擁有財富和幸福是你實現生命意義的基礎，MT DAO存在的目的就是讓更多的人找到他的幸福之路！參與MTDAO將會改變您的世界，如果你不相信或者不理解，對不起我沒有時間去說服您！',
        title8:'建造一個偉大的MTDAO社區，以傳播我們的信念，讓更多的人覺醒，為追趕去中心化金融革命浪潮而奮進前行！',
        img1:'shouye_01_cn.png',
        img2:'shouye_02_cn.png',
        img3:'shouye_03_cn.png',
        img4:'shouye_04_cn.png',
        img5:'shouye_05_cn.png',
        img6:'shouye_06_cn.png',
        img7:'shouye_07_cn.png',
        img8:'title_cn.png',
        more:'更多',
        index_txt:'DAO的概念最早由以太坊創始人V神提出，2016年出現了第一個DAO的項目TheDAO，15天募集超5000萬美金，是當時以太坊鏈上最大的眾籌項目，在TheDAO之後，DAO得到了快速的發展，區塊鏈研究機構Messari的報告認為「DAO 將成為區塊鏈時代發展的下一個大趨勢」。2020年屬於DEFI元年，2021年屬於NFT元年，2022年將屬於社區DAO的市場潮流。',
        index_txt_a:'DAO無疑是去中心化世界最富創新色彩的領域，有的DAO正在美國的懷俄明州一片土地上建立新的城市，有的DAO正在試圖解救為世界正義做出貢獻的人，有的DAO正在試圖發展太空事業登陸外星球，有的DAO在嘗試影響和轉變傳統金融大佬對加密世界的看法。DAO無疑是一場偉大的革命，它不僅是對傳統企業、管理方法、傳統商業模式的顛覆，更會對未來政府結構、政黨、法律、社會治理結構等產生深遠的變革和影響。',
        index_txt_b:'“DAO”是分佈式自治組織的縮寫，DAO是一種機制，通過它我們可以在互聯網上使用軟件實現合理經濟激勵。DAO是理想的世界，一切法律規章、合同協議等規則流程都是代碼寫成並清楚明白的運行與通過的。通過一系列公開公正的規則，可以在無人為解釋干預、無暗箱操作管理、無徇私舞弊等行為或無需依賴信任的情況下自主運行的分佈式組織形式，並將一切規則形式的控制權交給一套區塊鏈代碼程序寫死，不可破壞並自動執行的業務規則。',
        index_txt_c:'“DAO”代表“去中心化自治組織”，DAO是一個無CEO、無員工、無實體、無管轄權且無持有人的公司，但它仍然可以通過分散的代幣治理流程來進行全球性運營。與傳統組織不同的是，DAO是分散的，使用計算機代碼來協調DAO成員在全球範圍內走到一起來，自主前進並實現共同的目的或目標。區塊鏈技術使得這種機制變得可行！',
        index_txt_d:'股東資金被鎖定在底池智能合約之中，沒人可以單獨使用。DAO只存在於虛擬的数字世界之中，不受政府監管，無國界。這些資金是加密的数字貨幣，由智能合約控制，“規則即代碼，代碼即法律”，正在逐步成為現實。',
        index_txt_e:'當今世界的金融體系和政治系統兩者運作是緊密關聯的，我們如何走在時代變革創新的最前沿？個人財富價值、家庭幸福以及生命的意義如何實現？一個真正公平自由和文明的社會金融體系又如何實現？！在這個時代，每個人都有一個覺醒期，覺醒的早晚決定了個人命運，而先知先覺的人會最先成就自己 獲得財富與感受幸福！',
        index_txt_f:'面對當今世界局勢動蕩復雜，由於全球不可再生資源的異常緊缺，局部戰爭與沖突不斷發生。新冠疫情席卷全球又導致眾多實體生意關門倒閉， 俄烏戰爭導致大宗商品進出口失控。饑荒食物匱乏，瘟疫生物武器的出現。各國青年失業率居高不下，財富分配不均，通貨膨脹最嚴重的津巴布韋街邊隨處可見「賣錢謀生」的「億萬富翁」。２０２１年２月美國散戶股民聯合抱團拉升股價大戰華爾街金融巨頭事件，瑞士放棄中立國身份，凍結俄羅斯富豪資產，銀行人設信用崩塌，美元通脹貨幣超發稀釋全世界的財富，全球性的經濟大戰金融危機一觸即發！',
        index_txt_g:'天下熙熙皆為利來，天下攘攘皆為利往，所有的悲劇根源都是中心化人為可操控的經濟利益體在作怪。在此種復雜多變的大環境影響下，DAO去中心化自治組織與DeFi去中心化金融，如雨後春筍順應時代潮流發展，人心所向勢不可擋。其中比較有代表性的加密資產如：BTC，DOGE，SHIB，MakerDAO，Compound，YFI ,FIST等。長江後浪推前浪,浮事新人換舊人，全球分布式去中心化自治組織MTDAO元宇宙生態共識與DEFI社區自治加密資產MT橫空出世，應運而生！去中心化的DAO已邁出了取代傳統風投機構的第一步，MTDAO就像是一塊敲門磚，當這扇大門打開之後，一條更加寬廣的區塊鏈新金融之路就在前方。',
        index_txt_h:'MTDAO是由全球最大的黑客組織匿名者（Anonymous)成員與密碼朋克組織區塊鏈加密技術精英，共同發起的全球去中心化分佈式的DEFI多元化元宇宙生態共識平台，是一個網狀分散非線性擴張與發展的去中心化分佈式自治DAO組織。旨在利用集體力量和群體智慧發現並創造平台的投資價值，讓投資交易不再只是風投資本家的特權。讓每一位不甘於平庸且有野心的平凡普通草根民眾DAO組織成員，來進行僅用少許閑散資金在平台上進行社區自治，聯合做莊的參与和治理，經過時間的沉澱與平台價值共識，便可實現財富自由的偉大人生夢想！',
        index_txt_i:'MTDAO平臺依靠智能合約在區塊鏈上運行，沒有法律實體。通過一套完善的製度，無私的傳遞財富，並告誡其他區塊鏈愛好者避免在中心化項目裏跳坑與被割韭菜，通過製度保障所有參與者實現財富倍增，共同富裕。沒有莊家，沒有巨鯨，天生打散，數據公開透明，鏈上可查，打造去中心化鏈上金融普惠體系。在MTDAO平臺,持幣不動者,就是股東!需要用錢時隨時取出,有盈余時再放回,換個地方存款,讓資產保值增值更安全!如果您現在正在遭受貧窮或經濟壓迫！如果您仍有野心與追求夢想的勇士精神！那就身披鎧甲手持利劍加入MTDAO，打破不公平的中心化集權金融體系與枷鎖，讓我們成為一群改變世界夢想星辰大海的人，為實現區塊鏈領域裏去中心化金融的世外桃源而努力奮鬥！',
        part0:{
            title:'區塊鏈的基礎——DAO'
        },
        part1:{
            title:'MTG（NFT）是MTDAO的治理令牌，全球限量發行10000枚'
        },
        part2:{
            title:'MTDAO是一個去中心化全新的、無需許可，門檻較低的一站式金融服務生態網絡',
            title1:'DAO自治',
            text1:'DAO是一種全新的機構形態，就像一個全自動的機器，當它全部的程序設定完成后，它就會按照既定的規則開始運作。不受任何單個人的控制，卻有明確的目標，能夠自己進化和發展',
        },
        part3:{
            title1:'MT是MTDAO治理通證',
            text1:'幣安薄餅去中心化交易所，自由買賣交易',
            text2:'ULME是MTDAO發行的数字資產為未來生態系統賦能以及燃料',
        },
        part4:{
            text:'DEFI流動性挖礦，LP算力加成燃燒迴流循環挖礦賦能DAO組織成員共同建設生態'
        },
        cooperation:'合作夥伴',
        contact:'聯繫我們',
        bind_popup:{
            title:'請綁定邀請人',
            input:'請輸入地址',
            btn:'綁定'
        },
    },
    nft:{
        mtg_price:'當前價格',
        total_supply:'當日供應',
        sold:'全网售出',
        contract:'合约地址',
        search_input:'請輸入要搜索的社區',
        search_btn:'搜索',
    },
    nftDetails:{
        add_approve_num:'購買多個可以一次授權單次購買',
        approve:'授權',
        mtg_price:'MTG价格',
        total_supply:'當日供應',
        sold:'全网售出',
        introduce:'介紹',
        // introduce_txt:'MTG是存儲在區塊鏈上的唯一數位憑證或權利標記，是你在Binance NFT上MTDAO平臺的虛擬身份代表。 每枚MTG都有唯一的編號，若發生轉讓，則其享受的所有權益也會隨之轉移。 持有MTG者會享有MTDAO平臺的諸多權益分紅！ 並參與平臺生態建設，擁有其投票治理管理權！',
        introduce_txt:'Introduce MTG is the only digital certificate or right mark stored on the blockchain. It is the virtual identity representative of your MTDAO platform on Binance NFT.Each MTG has a unique number. If it is transferred, all its interests will be transferred accordingly. Those who hold MTG will enjoy many rights and interests of MTDAO platform, participate in the ecological construction of the platform, and have their voting governance and management rights! ',
        brief:'簡介',
        rule:'規則',
        rights:'權益',
        ntf_txt:'MTG（NFT令牌/社區股權）',
        ntf_txt_a:'全稱Metaverse Token Govern,元宇宙治理代幣的意思；是MTDAO元宇宙生態共識平臺的NFT令牌通證，也是MTDAO去中心化分布式自治組織的社區股權。是你在Binance NFT上MTDAO平臺的虛擬身份代表，同時也是後期生態版塊中鏈遊道具身份升級憑證的基礎條件。是平臺首個上線的生態版塊--NFT令牌/社區股權。通過完善的認籌機製，永續的分紅製度，聯合做莊，讓所有股東共進退。保障所有認購資產的股東在MTDAO實現共同富裕，打破傳統金融壟斷。人人是股東，人人可聯合開啟數字銀行來共識數字資產的價值，從散戶關心價格思維轉向莊家股東思維，關心交易所交易量和換手率思維。MTG代幣持有者將能夠提交提案並投票，來影響平臺發展和方向。MTG (NFT令牌/社區股權）是一個公平啟動的股權代幣認購項目，全球限量發行1萬枚(份)。全球區塊鏈愛好者任何成員均可參與認購，共同治理MTDAO元宇宙生態，無私募，無預留，零預售，零流通，全部在網站線上下單公平申購。它結合了NFT，DEFI與DAO的特點，結合MTDAO元宇宙生態平臺MT社區自治，LP算力加成及合成燃燒回流，雲算力循環挖礦的模式，同時通過建立創世節點，超級布道者，社區節點，聯盟節點，礦工及股東等DAO組織成員，共同建設賦能該生態平臺。',
        ntf_txt_b:'NFT令牌（Non-Fungible Token）是什麽？',
        ntf_txt_c:'它是存儲在區塊鏈上的唯一數字證書或權利標記 ，它保證了物品的獨創性，不可替代性及專有權，令牌是不能被逐漸替換或分割之後再替換的。它代表區塊鏈上的加密資產，具有獨特的識別碼和元數據。可以將不同物品（商品）彼此區分開來，這點與普通加密貨幣不同，所以又稱為「非同質化代幣」。它的應用會成為互聯網進化到3.0時代的重大標誌。互聯網正在從數據庫向價值庫轉化，而其基礎架構就是NFT、web3.0和DeFi這三大法寶。NFT是存在於區塊鏈上且無法復製的獨特加密令牌（代幣），它是互聯網盜版的最大克星！NFT可用於表示現實世界的物品，如藝術品和房地產；NFT可以用來代表人們的身份和財產權等；NFT還可以用於廣泛領域，可以消除中介機構，簡化交易並建構新的市場。NFT「令牌化」可以讓現實世界的有形資產更有效地買賣和交易，同時大幅降低欺詐性；可以把NFT令牌理解成為一幅畫，它可能屬於畫廊、博物館或個人，但觀眾始終可以在目錄或展覽中瀏覽到它。',
        purchase:'購買',
        community_sold:'本社區已售出',
        mask_purchase:'請選擇支付方式',
        payment:'USDT 餘額',
        gold:'支付金額',
        submit:'提交',
        rule_title_a:'MTG認購規則及說明',
        rule_arr:[
            '1.MTG總量１萬枚，由來自全球各地的上百個創世節點DAO組織社區負責分布式布道營銷。',
            '2.有意持有MTG的DAO組織成員可在MTDAO平臺NFT令牌界面下單購買或者場外交易獲取（錢包可互轉）。',
            '3.進入網站界面以後，找到您所屬的社區名稱,點開進入申購頁面按時價去下單即可。購買成功後MTG會自動轉賬進入你的付款地址錢包裏。',
            '4.下單申購時每次每筆訂單只能購買1個MTG，不可一次性購買多個。認購1枚MTG（ NFT令牌）即可成為平臺的治理股東。',
            '5.初始認購價為331U/股（第1--49股），自第50股(341U)開始，社區成員每認購10股漲10U(例:第 60 股351U, 第70股361U...)依次類推最高到第4740股時漲至5031U/股封頂，從第4740--10000股均為5031U/股。',
            '6.每枚已售出MTG的資金其中有固定的31U進入平臺開發賬戶，剩余金額的30%進入MT資金池交易合約賬戶，70%進入ULME資金池交易合約賬戶。',
            '7.每1枚MTG圖案都不一樣，並有唯一編號，獨一無二，按順序排列從#00001至#10000。無法分割成若幹個單位,只能以一個整體為單位來進行交易！',
            '8.MTG（ NFT令牌）若發生轉讓，則其享受的所有權益也會隨之轉移！',
        ],
        rights_title_a:'MTG持有者享受权益：',
        rights_txt_a:'<p>1.獲得1MT與50萬ULME的分紅權</p><p>2.ULME礦池的永久分紅權（每天分20ULME）</p><p>3.MT/ULME礦池提幣手續費4%加權分紅權</p><p>4.全網分享佈道收益MT的提現手續費1%加權分紅權</p><p>5.全網礦工挖礦算力激活手續費加權分紅權（1.5U/單位）</p><p>6.黑洞計劃C池BH幣賣幣手續費2%加權分紅權</p><p>7.礦池上線享受2倍算力加成</p><p>8.賬戶本身增值轉讓售賣權</p><p>9.成為V2高級礦工身份的基礎條件參與權</p><p>10.參與平臺生態建設，擁有其投票治理管理權</p>',

    },
    mt:{
        title:'當前MT價格：',
        title1:'未來是一個智能合約的世界，一個可編程的世界，新的世界將有新的貨幣，財政和組織結構，新的合作方式......',
        title2:'只有加密貨幣，才是真正的財富！"落袋為安"不是把幣換成錢，而是把錢換成幣！',
        title3:'莫讓貧窮成為你墮落的借口,真正的勇士敢於直面人生！開啟夢想之門,洗滌疲憊靈魂,讓人生因有夢想而精彩!',
        title4:'MT為您夢想插上翅膀，踏上掘金之旅，實現人生價值！乘風破浪,夢想啟航；MT與您在人生的巔峰相會！',
        title5:'人生天地間，若白駒過隙，忽然而已!請珍惜眼前事業良機!',
        text1:'"MT"全稱Metaverse Token ,元宇宙代幣之意，是MTDAO去中心化分佈式自治組織的社區代幣，也是MTDAO一站式去中心化金融生态服务网络平台的原生功能性治理及兌換代幣。全球限量發行31339枚。由MTDAO組織群體發行和控制，讓來自全球各地的去中心化DAO組織來自治管理。旨在打造一個真正去中心化屬於DAO組織成員自己且高度自治的加密数字鬧資產，把權利交還給參与者，共同通過DEFI理念推動生態發展！MT是一個去中心化的金融合約平台，使用MT的合約設計模式可以創建獨特的標準化金融產品並促進各種金融的創新。',
        text2:'MT由於採取分佈式的DAO社區共同自治管理形式，會穩步發展。MT幣本身的價值是由一群忠實的DAO成員佈道者共同共識信仰與努力佈道產生出來的。MT資產的持有者可以參与MTDAO去中心化金融的各種服務。MT可在幣安薄餅去中心化交易所隨時隨地靈活交易，自由買賣。智能合約寫死在鏈上自動運行，數據不可篡改，資金安全透明，MT會永遠存在於互聯網區塊鏈上，有靈魂有規劃，有共識有方向，MTDAO會將MT幣逐步推向主流資產行列，同時也會被推向主流中心化交易所。',
        text3:'合約基於BSC幣安智能鏈開發，DEFI協議構建的多元化金融生態。專註於数字資產共識交流與價值交互，致力於為全球用戶打造一個價值開放，安全加密，高速共享的数字貨幣經濟體。去中心化金融普惠每個DAO成員，為整個生態帶來更多活力。當前MT的主要功能是為用戶提供高安全性，高收益，在幣安公鏈上進行社區自治的交易及合成挖礦服務，為用戶提供多樣化的資產配置。',
        text4:'平台集DAO組織社區自治共建，NFT令牌價值交易，燃燒迴流循環挖礦，鑄幣銷毀黑洞計劃，去中心化交易所，借貸，元宇宙生態家園鏈游等多元化多功能版塊的DEFI於一身。讓MT幣產生網絡價值，共識效應，為用戶提供簡單易用的操作界面，讓每個人都能自由平等地使用該開放式金融服務。從而形成一個完整的經濟閉環全場景鏈上金融生態平台。',
        bonus_title:'MT幣交易手續費通縮分紅機制：',
        bonus:[
            {
                title:'買進5%：',
                text:'進入資金池，MT自動加池LP'
            },
            {
                title:'賣出5%：',
                text:'給全網LP權重排名靠前80%的DAO組織成員分紅DOGE幣'
            },
            {
                title:'互轉賬5%：',
                text:'給全網LP權重排名靠前80%的DAO組織成員分紅DOGE幣'
            },
            {
                title:'LP加池5%：',
                text:'給全網LP權重排名靠前80%的DAO組織成員分紅DOGE幣'
            },
            {
                title:'LP撤池5%：',
                text:'給全網LP權重排名靠前80%的DAO組織成員分紅DOGE幣'
            }
        ],
        remarks:'備註：在燃燒挖礦沒上線之前，扣掉的4項手續費（賣出，互轉，加池，撤池），暫時先以DOGE幣分紅的形式來分配給全網LP權重排名靠前80%的DAO組織成員。燃燒挖礦上線之後，會做相應調整，進行更加合理的分配！'
    },
    aboutUs:{
        title1:'我為眾生網絡自由而來，眾生財務自由乃吾之興！我為去中心化金融創新而生！',
        title1_1:'黑客組織匿名者（Anonymous)的簡介：',
        title2:'你我生來本善與自由,憑什麽被當今世俗所囚？！',
        title3:'加密不僅僅是一種技術，它還是一種互聯網原生經濟！',
        title4:'加快去中心化金融體系的建設，反對傳統的中心化垄斷。',
        title5:'一沙一世界, 一花一天堂！雙手握無限, 剎那是永恆！',
        title6:'MT DAO文化',
        text1:'「密碼朋克」從狹義上來說就是一套加密的電子郵件系統。密碼朋克組織是由一群旨在廣泛使用加密技術推動社會發展的活動家和夢想家組合而成的自由團體組織。早期的成員有很多的IT精英，個個都是如雷貫耳，有「維基解密」的創始人阿桑奇，BT下載的發明者布拉姆科恩，萬維網發明者蒂姆.伯納斯-李爵士、提出了智能合約概念的尼克薩博、Facebook的創始人之一肖恩.帕克，當然還包括比特幣的發明人中本聰。據統計比特幣誕生之前密碼朋克的成員討論並發明過失敗的加密貨幣和支付系統多達數十個。可惜都失敗了，但卻給比特幣的誕生留下了寶貴的經驗和借鑒的意義。後來中本聰融合了「密碼朋克」多名成員的發明成果，設計出了比特幣。或者說中本聰可能就是「密碼朋克」多名成員的化名。',
        text2:'他們擁有高超的計算機知識，先後攻擊PayPal、索尼公司網站、支援維基解密，甚至一度令美國中情局網站崩潰。他們可以進入大公司和政府部門內部網站，中斷他們的服務，刪除備份信息，截取電子郵件以及盜取各種文件。',
        text3:'他們不屬於任何一個企業和政黨，主要以其對幾個政府、政府機構、公司和山達基教會的各種網絡攻擊而聞名於世。比起黑客集團更像是一種社群，只要有相同想法或是理念，任何人都可以加入。',
        text4:'在《密碼朋克宣言》中有這樣一段話：「在電子信息時代的當今社會裏，隱私是必要的，隱私是秘密。私人事務是一個人不想讓整個世界知道的事情，但秘密的事情是一個人不想讓任何人知道的事情。隱私是由選擇性地向世界展示自己的力量。我們必需保護我們的隱私。必需有人站出來做一個軟件，用來保護個人隱私……我們計劃做這樣一個軟件。」朋克裏的這群人重視個人隱私和自由，同時也是這種精神的踐行者。',
        text5:'MTDAO、WB3、加密資產DEFI、“元宇宙”註定是億萬平民與建制派的博弈。科技越來越高度發達，是人類文明高度繁榮的象徵。在這文明的背後，更多的確是極少數人的狂歡。生存資料越來越往市場寡頭集中，經濟越來越往虛擬化發展。普通民眾幾乎很難再享受到時代發展的紅利。疫情經濟把全球社會本身的社會矛盾推向高潮，全球社會本質的社會矛盾是什麼呢？那就是貧富差距。全球不到1%的人掌握全球90%的財富。',
        arr1:[
            '匿名者核心理念是「無名無份（匿名），平等地位，呼籲網絡自由」',
            'We are Anonymous.（我們是匿名者）',
            'We are a Legion.（我們是軍團）',
            'We do not forgive.（我們不會原諒）',
            'We do not forget.（我們不會忘記）',
            'Expect us.（期待我們）'
        ],
        arr2:[
            {
                title:'文化理念：',
                text:'共創共建，共治共享，開放自由！'
            },
            {
                title:'使命：',
                text:'為大眾提供一個全新無需許可，資產自管自控，門檻較低的去中心化鏈上普惠金融服務生態平臺！'
            },
            {
                title:'願景：',
                text:'推動去中心化金融體系創新發展，讓平民草根逆襲實現財務自由，成為億萬大眾的財富創造者！'
            }
        ],
    },
    help:{
        title: '新手幫助',
        help:[
            {
                title:'1.如何創建錢包？',
                list:[
                    '點擊「資產」→「創建錢包」，選擇錢包類型，輸入創建信息，創建錢包。',
                    '創建錢包步驟',
                    '設置錢包名稱、錢包密碼、密碼提示信息',
                    '根據提示保存錢包助記詞',
                    '驗證助記詞',
                    '創建成功',
                    '溫馨提示',
                    '助記詞（私鑰）是恢復錢包的唯一方式，一旦丟失，資產將無法找回，請務必安全保管！',
                    '助記詞盡可能采用物理介質備份，例如用筆抄在紙上或使用防火防水防腐蝕的「助記詞密盒」等，禁止截屏或者拍照，以防泄露。',
                ]
            },
            {
                title:'2.如何導入錢包？',
                list:[
                    '點擊「資產」→「導入錢包」，選擇錢包類型，導入錢包。',
                    '導入錢包步驟',
                    '輸入錢包備份',
                    '設置錢包密碼、密碼提示信息（可選）',
                    '點擊「開始導入」',
                    '溫馨提示',
                    '若導入過程中出現報錯，請參考教程。',
                    '若導入后地址不同，說明導入的助記詞（私鑰）是錯誤的。',
                ]
            },
            {
                title:'3.如何備份錢包？',
                list:[
                    '點擊「資產」→「選擇錢包」，選擇錢包進入管理頁面，即可備份錢包。',
                    '備份錢包步驟',
                    '點擊「備份錢包」按鈕',
                    '根據提示正確抄寫助記詞或者私鑰',
                    '將助記詞或者私鑰保管在安全的地方',
                    '溫馨提示',
                    '錢包的備份是找回資產的唯一途徑，務必安全保管。',
                    '助記詞與私鑰作用相同，推薦備份助記詞。',
                    '推薦使用助記詞密盒保管助記詞',
                ]
            },
            {
                title:'4.如何發起轉賬？',
                list:[
                    '打開資產錢包選擇要轉賬的幣，點擊「轉賬」，輸入轉賬金額、收款地址，完成密碼驗證即可。',
                    '如何發起收款',
                    '打開錢包，點擊上方的錢包地址，將錢包地址發送給對方。',
                    '溫馨提示',
                    '如果對方使用MT錢包向你轉賬，向對方出示收款二維碼即可。',
                ]
            }
        ]
    },
    share:{
        title:'邀請分享',
        text1:'掃一掃',
        text2:'或複製連結',
        text3:'複製邀請連結',
        text4:'邀請地址',
    },
    setup:{
        text1:'MTDAO發展，人人有責； 社區自治，聯合做莊！ 為促進平臺永續發展，您有任何良好建議，請線上留言或發送至郵箱： mtdao777@gmail.com',
    },
    tips:{
        title:'敬請期待',
        title2:'複製成功',
        title3:'複製失敗',
        title4:'請先鏈接錢包',
        title5:'服務器維護中',
        title6:'網站服務器正在維護，給您帶來的不便，敬請諒解。',
        title7:'請確認您的邀請人是',
        title8:'確認',
        title9:'取消'
    },
    myCommunity:{
        title:'直推用户',
        text1:'查看',
        text2:'個人算力',
        text3:'社區算力'
    },
    planning:{
        title:'MTDAO發展規劃',
        part1:[
            {
                title:'使命',
                text:'讓人人擁有資產的所有權與控制權！推動去中心化金融體系創新發展！'
            },{
                title:'願景',
                text:'MTDAO去中心化一站式金融服務生態網絡成為全球70億人的資產所有權與控制權的工具'
            },{
                title:'價值觀',
                text:'共創共建、共治共享、開放自由、人人可參与！'
            },
        ],
        part2:{
            title:'MTDAO是什麼',
            text:'MTDAO是由密碼朋克組織相關成員發起的一個，100%去中心化的、全新的、無需許可、門檻較低的一站式金融服務生態網絡。在這樣一個生態網絡中，完全以DAO組織，社區自治的形式運行。用戶就是自己資產的管理者，每個用戶對自己的資產擁有完整的控制權和所有權。並且可以自由的進入到DEFI協議，參与到其中的服務。',
            title2:'關於密碼朋克',
            textArr:[
                '中本聰的比特幣白皮書最早發佈於“密碼朋克”。20世紀80年代末，一股“密碼朋克”的潮流悄然興起。參与者是一批神秘的“極客天才”。他們提倡大規模使用，強加密算法來保護自身隱私，免受攻擊;同時反對任何政府規則的密碼系統。該聯盟沒有正式的領導階層。一般情況下通過加密郵件列表進行聯繫。',
                '1992年，英特爾高級科學家Tim May最先發起這一加密郵件列表組織。而“密碼朋克”這一術語的首次出現，則是在1993年埃里克.休斯出版的《密碼朋克宣言》上。“密碼朋克”用戶約1400多人。該郵件列表內上千名用戶主要討論包括：數學、加密技術。',
                '計算機技術、政治、哲學也包括私人問題等方面的內容。其早期成員由非常多IT精英：比如「維基解密」創始人阿桑奇、BT下載的作者布拉姆.科恩、萬維網發明者蒂姆.伯納斯-李爵士、提出了智能合約概念的尼克薩博、Facebook的創始人之一肖恩.帕克、還有比特幣的發明人中本聰等。據統計，比特幣誕生之前，密碼朋克的成員討論，發明過失敗的數字貨幣和支付系統多達10種以上。後來中本聰融合了「密碼朋克」多名成員的發明成果，設計出了比特幣。或者說，中本聰本就是「密碼朋克」多名成員的化名。',
                '“密碼朋克”原本就是自由共識的發起人。人類社會歷史上的兩次著名的“密碼戰爭”都是“密碼朋克”組織成員跟平民一起向美國政府發起的正義之戰。最終都是以人民的勝利而告終。前兩次“戰爭”更多的是反對政府通過擁有的龐大的數據中心去侵犯個人隱私，掌控每一個互聯網用戶的信息。'
            ]
        },
        part3:{
            title:'1.1 什麼是DAO?',
            title2:'區塊鏈的共識基礎---DAO',
            textArr:[
                {
                    title:'DAO：',
                    text:'分佈式自治組織（Distributed Autonomous Organization）的縮寫，這是一種基於區塊鏈的組織結構形式。'
                },{
                    title:'DAO有時候也被稱為DAC（Distributed Autonomous Corporation），',
                    text:'意思是“分佈式自治公司”，和DAO的含義差不多。無論是DAO還是DAC，都是指通過一系列公正公開的規則，可以在無人干預和管理的情況下自主運行的組織形式。'
                }
            ],
            textArr2:[
                '這些規則往往會以開源軟件的形式出現，每個人都可以通過購買該組織的股份權益，或者提供服務的形式來成為該組織的參与者。',
                'DAO就像是一個全自動的機器人，當它全部的程序設定完成后，它就會按照既定的規則開始運作。',
                'DAO的形態非常廣泛：加密資產、系統或者機構。',
                'DAO的形式：貨幣傳輸（如Bitcoin）,應用平台（Ethereum）,域名管理系統（Namecoin）。',
                '每個DAO都有其自己的條款和條件。',
                '因此，DAO是一種全新的機構形態，可能是未來互聯網上組織形態的雛形，不受任何單個人的控制，卻有明確的目標，能夠自己進化和發展。'
            ]
        },
        part4:{
            title:'1.2DAO的發展和演變',
            textArr:[
                '最初源於Ori Brafman在The Starfish and the Spider中提到的關於去中心化組織的主題及Yochai Benkler在The Wealth of Networks提出的“同行生產”（peer production）',
                '2013年，Daniel Larimer首次提出類似DAO的概念----去中心化組織企業（DAC）',
                '2014年，Daniel Larimerz再次補充了DAC的概念。隨後，Vitalik闡述了對DAC的認識，並經由Daniel Suarez的Daemon一書啟發，提出了區塊鏈語境下的DAO。',
                'DAO首次正式出現是在2015年以太坊區塊鏈上的一份名為DAO的智能合約，即技術框架、工具，常被理解為類似非盈利型組織的自組織。',
                '2016年，首個DAO——The DAO被開發出來，成為當時世界上最大的眾籌項目。從此之後，一系列的DAO被相繼推出，如：Polkadot 、AragonDAO、Dash DAO等。',
                'The DAO上鏈后不久，便遭到黑客攻擊，黑客利用智能合約代碼中的可重入性漏洞（reentrancy vulnerability ）發動了攻擊，造成了超過5000萬美元的以太幣被盜，最後社區不得不以硬分叉的方式追回了資金。但此舉違背了“代碼即法律”的準則，引發了巨大爭議。'
            ]
        },
        part5:{
            title:'1.3 DAO的分類',
            textArr:[
                '1、贈款型DAO（Grants DAOs）的治理，最初是通過不可轉讓的股權進行的，這意味着參与很大程度是受社會資本，而不是金融回報驅動的。',
                '2、協議型DAO（Protocol DAOs）將權利從核心團隊轉移到社區中，為項目在市場發行可置換代幣（fungible token）催生了一種新的方式。',
                '3、投資DAO允許成員彙集資本並在項目的最初階段進行投資。',
                '4、服務型DAO，隨着這麼多代幣的出現，項目需要人才。服務型DAO從事人才分配，使用鏈上憑證把資源從一個DAO輸送和分配到另一個DAO。服務型DAO為個人創建去中心化工作小組為開放的互聯網工作。',
                'MTDAO是幾種類型DAO的組合，項目由“密碼朋克”發起，但Token歸屬、智能合約、swap流動性LP的所有權將全部銷毀。每當Telegram 里有人問誰是項目負責人時，就會有回答：No one and everyone.'
            ],
            title2:'MTDAO去中心化一站式金融服務生態網絡成為全球70億人的資產所有權與控制權的工具。',
            text:'MTDAO、WB3、加密資產DEFI、“元宇宙”註定是億萬平民與建制派的博弈。科技越來越高度發達，是人類文明高度繁榮的象徵。在這文明的背後，更多的確是極少數人的狂歡。生存資料越來越往市場寡頭集中，經濟越來越往虛擬化發展。普通民眾幾乎很難再享受到時代發展的紅利。疫情經濟把全球社會本身的社會矛盾推向高潮，全球社會本質的社會矛盾是什麼呢？那就是貧富差距。全球不到1%的人掌握全球90%的財富。',
            text2:'由於技術進步和全球化，硅谷高科技的發展和金融的發展，經濟越來越向数字經濟發展，金融越來越向数字金融發展，這種情況下普通的平民，他們得到的利益相對來說就越來越少。在這個背景下，就爆發了人類金融史上“里程碑”的事件WSB“華爾街金融平民主義運動”。'
        },
        part6:{
            title:'WSB運動',
            textArr:[
                {
                    title:'在reddit的股巴,叫wallstreetbets，簡稱WSB，是一個股票投資群。',
                    text:'社群普遍看好一支叫Gamestop的股票。它是一家老牌賣遊戲相關的線下連鎖。這支股票在過去的幾年被一些大基金做空得厲害，因為基金普遍覺得這家公司主營業務是夕陽產業。WSB社群看好是因為公司要換一個CEO來改革這家公司。股票代碼：GME。從年初最低4美刀，一路上漲到40多刀。然後有一個做空的基金就坐不住了，發推說：WSB買GME的最終是輸家，這個股票不值這麼多錢。這樣惹怒了社群的人。此群的意見領袖孟加拉裔的一個個人經濟學家做了數據研究，表示GME的股票已經被空頭超賣了。按當時的數據，市場上能買到的總計0股，空頭已經賣了140股。也就是只要大量買進抬高價格，這些空頭最終會被扎空，被迫買入股票來平倉，不管多高都得買。'
                },
                {
                    title:'',
                    text:'為了教訓這些華爾街基金，社群號召在一起開始了買入運動。目標扎空機構，從他們身上狠撈一筆。GME一路漲到80多。最高430刀。有的機構被割慘重，突然出現一個事件：'
                },
                {
                    title:'',
                    text:'Robinhood（美最大的散戶股票交易平台）突然宣布停止GME股票的購買。只許賣出，不能買入。'
                },
                {
                    title:'',
                    text:'Robinhood刷了流氓，機構輸了直接掀桌子。中心化作惡開始了。'
                },
                {
                    title:'',
                    text:'運動最終雖然平民取得了勝利，但是卻是建立在大多數平民參与者血虧的基礎之上。'
                },
                {
                    title:'',
                    text:'當然最終華爾街機構也被“抱團”的百萬平民割了“韭菜”損失慘重。'
                },
                {
                    title:'',
                    text:'我們希望以WSB為代表的平民精英與勇士們來到這個最好的戰場——MTDAO。這是真正的100%去中心化的組織，沒有中心化作惡的世界。我們倡導MTDAO是由社區推動的，我們拒絕資本作為寡頭早期的介入，形成主導。我們期盼是由社區推動的，如果你也是一個平民，同時準備打破枷鎖、有改變世界的想法，那麼歡迎你加入MTDAO。'
                },
                {
                    title:'',
                    text:'即使你是富有的階層，只要你是平民身份。那麼你仍然面臨跟億萬普通平民一樣的危機。我們一起來回顧一下正在發生的和歷史上的“醜惡”事件：'
                },
                {
                    title:'',
                    text:'美歐國家因為俄烏衝突，凍結了俄羅斯外匯金融資產達3000多億美元。美國官員近日竟然表示美國不打算歸還，已經沒收俄羅斯富豪的資產，他們將以一種更好的方式使用這筆錢。美國總統正在考慮如何處理這筆資產。我們說，美國準備私吞這筆巨款，這是真的嗎？美國怎麼敢這樣做呢？讓我們先來看看，美歐到底凍結了俄羅斯多少金融資產？各國制裁的名單如下：'
                },
                {
                    title:'排在第一名的是美國，',
                    text:'他們現在正在討論凍結俄羅斯1320億美元的黃金儲備。美國司法部成立了跨大西洋工作組，尋找俄羅斯富豪，在美國和歐盟中的遊艇、豪華公寓、私人飛機和巨額存款，進行凍結和沒收。美國已宣布凍結8名俄羅斯富豪在美國的資產。'
                },
                {
                    title:'排在第二的是瑞士。',
                    text:'瑞士已經凍結了價值75億瑞士法郎的資產，摺合80億美元。'
                },
                {
                    title:'排在第三的是荷蘭。',
                    text:'荷蘭銀行凍結了俄資金近1.45億歐元，信託業凍結了近2.43億歐元，養老基金凍結了37.99億歐元，投資企業凍結了390億歐元。'
                },
                {
                    title:'第四是英國。',
                    text:'英國沒收了俄羅斯寡頭的房產，來安置烏克蘭難民。英超切爾西足球俱樂部的老闆叫阿布拉莫維奇，他是俄鋼鐵公司的大股東。如今被踢出切爾西董事會。資產也被英國沒收了。'
                },
                {
                    title:'第五是法國。',
                    text:'法國凍結俄央行220億歐元資產，另外凍結1.5億歐元俄羅斯自然人的資金。 沒收了價值5億歐元俄羅斯人的房產。'
                },
                {
                    title:'第六是意大利。',
                    text:'沒收價值1.4億歐元的俄羅斯富豪的財產。包括富豪的一艘價值6500萬歐元的遊艇。'
                },
                {
                    title:'第七是波蘭。',
                    text:'凍結近3300萬美元的俄羅斯的賬戶。沒收俄羅斯人在華沙的房產。包括俄大使館下屬的學校建築和一所外交官公寓。'
                },
                {
                    title:'第八是德國。',
                    text:'德國政府扣押俄富豪，世界上總噸位最大的機動遊艇。價格約為6億美元。'
                },
                {
                    title:'第九是加拿大。',
                    text:'把俄羅斯運送疫苗的運輸機扣押在了多倫多機場。'
                },
                {
                    title:'',
                    text:'我們說西方文明的基石，就是保護私人財產，神聖不可侵犯。他們所扣押的都是俄羅斯富豪的私人財產。'
                },
                {
                    title:'',
                    text:'大家都關心，這些被凍結的俄巨額的海外資產會怎麼處置呢？'
                }
            ]
        },
        part7:{
            title:'讓我們先來看看過去，他們都是如何處置的？',
            textArr:[
                '2001年美國打阿富汗這個窮國。美國就凍結阿富汗70億美元。之後美國總統大筆一揮，直接把70億美元就分掉了。',
                '2003年，美國發動了伊拉克戰爭。就打了中東國家伊拉克。戰後薩達姆家族的70億美元的資產，被吃的僅剩下60萬美元了。',
                '2011年，非洲國家利比亞爆發了內戰。歐美國家趁機就凍結了卡扎菲總統的資產，合計約800億美元。還有利比亞中央銀行140多噸黃金。這個數據遠不止於此，有人估計卡扎菲的秘密資產，總值可能超過2000億美元。10多年過去了，現在被利比亞政府追回的資產不如九牛一毛了。',
                '俄羅斯這次被凍結，被扣押的海外資產，也許是個天文數位。 有人估計總規模會高達上萬億美元。 俄財長說，俄羅斯約有3000億美元的黃金和外匯儲備，已被凍結。 占到俄國際儲備總額的近一半。 俄富豪的個人海外資產，被扣押的至少有800億美元。 還有各大企業大量的海外投資、債券等等。'
            ],
            textArr2:[
                '現在美國率先表示，拒絕歸還俄羅斯富豪們的這筆錢。這等同於強盜行為。搶了錢還不還了。如果美國這樣做得逞了。那其他國家都會效仿。這個世界將會變成什麼樣子呢？這就給各國都提了一個醒。對存在美國的資產，一定要早做打算。萬一哪一天。美國採取對俄羅斯一樣的做法，將會損失慘重',
                '我們說美國和西方凍結了俄羅斯的外匯儲備和資產。這是各國發橫財的機會，他們大發戰爭之財。這種不義之財想吞進去。那俄羅斯能答應嗎？這就要看後面是如何博弈的了。',
                'MTDAO歡迎WSB的勇士們來到這個最好的100%去中心化的新“戰場”，跟隨“加密朋克”、中本聰、聯合億萬萬平民們，一起去打破枷鎖，建立一個全新的、無需許可、門檻較低的一站式金融服務生態網絡。在這樣一個生態網絡中，用戶就是自己資產的管理者，每個用戶對自己的資產擁有完整的控制權和所有權。'
            ]
        },
        part8:{
            title:'MTDAO的去中心化一站式金融服務生態網絡具體要做什麼？',
            textArr:[
                {
                    title:'',
                    text:'MTDAO將圍繞去中心化数字貨幣及NFT發行、合成資產燃燒挖礦、預言機、跨鏈資產、聚合器、去中心化交易平台、抵押借貸、金融衍生品發行、錢包等金融全景業務領域陸續展開，讓大家能夠體驗DAO自治與DEFI相結合帶來的全新一站式金融服務體系。'
                },{
                    title:'',
                    text:'2020年度，區塊鏈最具代表性的應用和發展最為迅猛的領域:DEFI，也就是分佈式金融，或者叫做去中心化金融，已經受到了全球金融行業和科技界的廣泛關注。也被認為是金融科技領域創新模式最前沿的陣地。更預示着全球範圍內新一代金融科技革命正在來臨。DEFI的發展，如火如荼。但是其實大多數人，都並不真正意義上了解DEFI的業務形態，運營規律和發展規則，很多人以看不懂為借口，選擇視而不見，也有很多人，未經深入考察，就簡單冠以“資金盤”“欺詐”因此，很多區塊鏈行業資深的參与者，在DEFI浪潮中錯失良機，無所作為。但是事實上並沒有關係，因為DEFI的征程剛剛開始。我們認為DEFI即將引發的產業變革是深層次的，長期性的。在未來沒有人能夠置身事外。所以每個人都應該儘早理解DEFI帶來的大變局，並且思考如何把握這輪變革，能夠給每個人帶來怎樣的時代機遇？'
                },{
                    title:'',
                    text:'但是要理解DEFI，並非是簡單的理解幾個新的概念，看幾個新項目就可以充分掌握，而是需要思考的整體系統性升級，從DEFI最根本的要點和邏輯出發，從深層次全方位理解DEFI，這波全球性金融變革新浪潮。 我們要理解DEFI為什麼在這個時候能夠佔到歷史的舞台上，我們就要回顧一下區塊鏈的發展歷程：'
                },{
                    title:'事實上，我們可以把區塊鏈分為三個發展階段：',
                    text:''
                },{
                    title:'',
                    text:'第一個階段是以比特幣為代表的區塊鏈1.0時代。它是相對比較簡單的分佈式記賬，是對區塊鏈技術的基礎性應用。'
                },{
                    title:'',
                    text:'而2.0則是以太坊為代表的分佈式計算時代。它利用了公鏈與智能合約的可擴展性衍生出了更多的資產。'
                },{
                    title:'',
                    text:'而在產業區塊鏈和DEFI大發展大融合的時代，就出現的區塊鏈3.0，這就是分佈式組織（DAO）時代，這個時代將以更高效、更可靠、更加多元化的區塊鏈基礎設施為基礎，助力各行实现商业模式变革，组织变革（DAO）、效率变革并开启全新的经济周期。所以说，我们每个人都要真正意义上搞明白，区块链世界3.0时代的机会，才能够分享这个时代的红利。对于DEFI与DAO的学习，了解必须深入，在以后的时间，MTDAO将围绕去中心化数字货币及NFT发行、流动性挖矿、合成资产、预言机、跨链资产、聚合器、去中心化交易平台、抵押借贷、金融衍生品发行、钱包等金融全景业务领域陆续展开，让大家能够体验DAO自治与DEFI相结合带来的全新金融体系。'
                },
            ]
        },
        part9:{
            title:'MTDAO生態階段經濟系統簡介',
            text:'MTDAO成立於2021年11月，由密碼朋克組織相關成員與加密技術經營組成的開發團隊、數百名社區志願者和龐大的粉絲群，我們希望我們一起構建未來這個去中心化金融新生態。我們是一個人民的DAO，從一開始就與我們的社區在一起。拒絕前期的資本寡頭進入主導市場，100%由社區來推動。',
            title2:'MTDAO',
            textArr:[
                {
                    title:'',
                    text:'MTG：MTDAO的治理令牌（NFT令牌/社區股權）MT：MTDAO平台的治理代幣與社區代幣（社區自治，聯合坐莊）ULME：MTDAO去中心化交易所的治理代幣/算法激勵代幣（燃燒迴流，循環算力收益）'
                },{
                    title:'1.MTG MTDAO的治理令牌：',
                    text:'發行量10000張MTG是存儲在區塊鏈上的唯一数字證書或權利標記。它保證了權利的唯一性、不可替代及專屬性，令牌是不能被逐漸替換或分割之後再替換的，它代表區塊鏈上的加密資產及權益具有獨特的識別碼和元數據。它與普通的加密貨幣不同，所以又稱為“非同質化代幣”。它的應用會成為互聯網進化到Web3的重大標誌。'
                },{
                    title:'2. MT，MTDAO的治理代幣',
                    text:'全球限量發行31339枚，由MTDAO組織群體發行，DAO組織自治管理。旨在打造一個真正去中心化且社區高度自治的加密数字資產。權利交還給參与者，共同DEFI機制推動生態發展。'
                },{
                    title:'3. ULME，MTDAO發行的數字資產',
                    text:'主要是算法激勵賦能生態發展。ULME是MTSWAP去中心化交易所的平台治理代幣。全球限量發行193.1億枚。MTSWAP是建立在幣安智能鏈上的自動做市交易協議。允許用戶通過Pancakeswap v2上的MT與ULME代幣，兩幣聯動、燃燒迴流、合成算力、在礦池中挖掘社區代幣MT與ULME。幫助用戶“擺脫枷鎖”，為每個人提供可替代的金融和数字商業工具。'
                }
            ]
        },
        part10:{
            title:'MTDAO生態平台的未來發展規劃',
            textArr:[
                {
                    title:'2022年1-2月',
                    text:'——密碼朋克組織成員及區塊鏈技術精英，聯合組建技術開發團隊對MTDAO平台經濟模式的籌劃準備與立項。'
                },{
                    title:'2022年3-4月',
                    text:'——全球部分社區投票通過MTDAO的經濟模型與營銷方案。'
                },{
                    title:'2022年5月',
                    text:'——全球創世節點招募，為平台起航打下堅實基礎。'
                },{
                    title:'2022年6月中旬',
                    text:'——MTDAO平台的首個生態版塊MTG（NFT令牌）上線，完善並升級平台功能。'
                },{
                    title:'2022年7月末',
                    text:'——DAO組織社區與MTDAO平台治理代幣MT上線。'
                },{
                    title:'2022年10月份',
                    text:'——去中心化交易所平台代幣ULME上線，資產合成，燃燒迴流，循環農場算力開啟上線。'
                },{
                    title:'2023年第一季度',
                    text:'——神秘C幣黑洞計劃底層代碼搭建，測試與上線。'
                },{
                    title:'2023年第二季度',
                    text:'——NFT交易市場底層代碼搭建，MTG令牌拍賣測試與上線。'
                },{
                    title:'2023年第三、四季度',
                    text:'——MtSwap去中心化交易所底層代碼搭建，測試與上線，啟動生態用戶裂變計劃。增加平台用戶流量，用戶量預計突破10萬戶，市值百億美金！平台治理代幣MT上知名頭部中心化交易所交易，外部市場資金進入！'
                },{
                    title:'2024年第一季度',
                    text:'——去中心化功能性錢包底層代碼搭建，測試與上線使用完善階段，用戶數據遷移。'
                },{
                    title:'2024年第三季度',
                    text:'——MTDAO平台質押借貸底層代碼搭建，測試與上線。'
                },{
                    title:'2024年第四季度',
                    text:'——元宇宙生態家園發展方向雛形確定，底層代碼搭建與測試。'
                },{
                    title:'2025年第二季度',
                    text:'——元宇宙生態家園成功上線，DAO組織成員體驗，對外宣發，外部市場用戶陸續進入。'
                },{
                    title:'',
                    text:'互聯網把經濟信息化，區塊鏈把經濟引向了數字化、數據化。一場以區塊鏈分布式商業思維為引領，區塊鏈技術為驅動的社會變革已經來臨。人類社會將全面走向數字經濟、數字文明。區塊鏈正在重構全球去中心化新經濟體系、去中心化新貿易體系、去中心化新金融體系。以新能源與算力為生產力的數字經濟新時代正在顛覆以石化能源與電力為生產力的工業經濟。無論你是主動接受還是被動裹挾，都會被時代推著前進。作為數字經濟時代最重要的創富資源：數據，被淪為「數據商品」。我們不能接受，作為數據資源創造者的我們，卻沒有數據的所有權，反而被各個行業的「寡頭」收割。在這個時代數據就是資源，數據就是財富，作為創造者的個體，必須自己掌控自己創造的資源與財富本身，才能夠擁有新經濟紅利的分配權。本質上講：這是一場「所有權」之爭，更是紅利之爭，財富分配權之爭。MTDAO是億萬萬平民聯合去擁有本應屬於自己的數字資源與數字財富的產權之爭，更是億萬平民造富運動的一場實驗。我們是正義的一方，你可以選擇放棄這樣的權利，但是作為一個有夢想、有正義的「勇士」一定會選擇我們一起構建MTDAO創新金融服務生態網絡，為加快人類社會去中心化金融體系的建設、為新的人類數字社會做出自己的貢獻。覺醒、為自己而戰，記住！我們是正義的！一起共享數字新經濟的紅利。'
                }
            ]
        }
    },
    idLevel:{
        arr:[
            {
                title:'萬人共識股東(MTG-NFT令牌)',
                url:'/nftInfo'
            },{
                title:'Defi Farmer (V0/V1/V2/V3)',
                url:'/My_Farmer'
            },{
                title:'前31名V2',
                url:'/Vip_ranking?type=1'
            },{
                title:'聯盟節點（前18名V3）',
                url:'/Vip_ranking?type=2'
            },{
                title:'社區節點 (99名)',
                url:'/Week_ranking?type=1'
            },{
                title:'超級佈道者 (88名)',
                url:'/Week_ranking?type=2'
            },{
                title:'創世節點 (67名)',
                url:'/Genesis_node'
            },{
                title:'全網LP權重佔比排名前100位DAO成員',
                url:'/LP'
            }
        ]
    },
    genesisNode:{
        title:'創世節點',
        btn1:'贖回',
        btn2:'領取',
        btn3:'領取明細',
        title1:'個人當前基礎條件與權益分紅狀態',
        title2:'待領取收益',
        subtitle1:'質押LP:',
        subtitle2:'質押MTG:',
        text1: '我的等級：',
        text2: '我的社區算力：',
        text3: '質押MT/USDT LP:',
        text4: '質押UML：',
        text5: '質押：',
        text6: '佈道V0數量 /MAX/個 (當前進度：/PRESENT///MAX/)',
        text7: '培育直屬市場V2數量 /MAX/個 (當前進度：/PRESENT///MAX/)',
        text8: '小市場算力：',
        text9: 'MT買幣與LP撤池分紅',
        text10: 'MT互轉賬分紅',
        text11: '算力单位激活MT分紅',
        text12: 'ULME買幣與LP撤池分紅',
        text13: 'ULME互轉賬分紅',
    },
    receivingDetails:{
        title:'領取明細'
    },
    LP:{
        part0:{
            title:'合約：'
        },
        part1:{
            title:'您錢包賬戶的餘額',
            text1:'USDT餘額：',
            text2:'餘額:',
            text3:'您的總資產',
            text4:'最新價格',
        },
        part2:{
            title:'全網 |NAME|-USDT LP當前流動池狀態',
            subtitle:'全網流動池代幣總額(|NAME|-USDT LP):',
            text1:'已入池',
            text2:'24h成交量',
            text3:'24h手續費',
        },
        part3:{
            title:'/USDT個人 LP當前流動池狀態',
            title2:'(近7天)',
            subtitle1:'您的流動池代幣總額(|NAME|-USDT LP):',
            subtitle2:'您的流動池中的份額:',
            subtitle3:'我質押的流動池代幣總額(|NAME|-USDT LP):',
            text1:'已入池',
            text2:'預估24h手續費',
        },
        btn:'+ 增加流動性',
        btn1:'添加並質押',
        btn2:'贖回',
        btn3:'質押',
        btn4:'減少',
        part4:{
            text1:'待領取收益',
            btn:'領取'
        },
        part5:{
            title:'全網LP權重占比排名前100位DAO成員分紅地址',
            text1:'排名',
            text2:'LP佔比',
            text3:'地址',
        },
        add:{
            input:'請輸入數目',
            text1:'我的餘額',
            text2:'添加|NUM|USDT需要:',
            btn1:'授權',
            btn2:'添加'
        },
        reduce:{
            title:'減少流動性',
            text:'當前',
            btn1:'授權',
            btn2:'提交'
        },
        pledge:{
            title:'質押流動性',
            text:'當前',
            text2:'質押後可抵',
            btn1:'授權',
            btn2:'提交'
        },
    },
    Vote:{
        title:'提案投票',
        d:'天',
        h:'時',
        m:'分',
        s:'秒',
        join:'立即參與',
        end:'已結束',
        text1:'共創共建 共治共享 開放自由',
        text2:'參與人數',
        text3:'累計票數',
        text4:'訪問數量',
        text5:'投票進行中',
        text6:'投票已結束',
        btn1:'同意',
        btn2:'不同意',
        text7:'支持',
        text8:'反對',
        text9:'活動規則',
        text10:'已投票',
        text11:'您已經投過票了',
        text12:'投票成功',
        text13:'投票尚未開始'
    },
    Miner:{
        title1:'當前價格',
        title2:'農耕礦池',
        title3:'我的等級:',
        title4:'我的算力:',
        title5:'我的收益:',
        title6:'我的農耕:',
        title7:'再生算力(只可抵扣ULME的農耕算力)',
        title8:'再生算力(只可抵扣MT的農耕算力)',
        text1:'全網農耕算力:',
        text2:'全網社區算力:',
        text3:'農耕算力:',
        text4:'社區算力:',
        text5:'農耕收益:',
        text6:'社區收益:',
        text7:'前31-V2收益:',
        text8:'前18-V3收益:',
        text9:'佈道收益:',
        text10:'領取',
        text11:'合約:',
        text12:'去交易',
        text13:'授權',
        text14:'餘額',
        text15:'農耕',
        text16:'可用再生收益:',
        text17:'已領取總收益:',
        text18:'本次領取的動靜態|NAME|/ULME與佈道收益|NAME|總價值',
        text19:'，提幣後會減少',
        text20:'領取時實際領取價值超過',
        text21:'倍的部分將會被扣減',
        text22:'確認',
        text23:'取消',
        text24:'算力',
        text25:'需要的',
        text26:'不足,可能還需要',
        text27:'我的餘額',
        text28:'餘額',
        text29:'我的任務',
        text30:'有效佈道',
        text31:'人',
        text32:'農耕次數',
        text33:'次',
        text34:'社區算力',
        text35:'質押',
        text36:'是否確認領取',
        text37:'您的等級與實際不符，可能影響您的算力，請點擊升級',
        text38:'升級',
        text39:'農耕算力'
    },
    nftInfo:{
        title1:'質押池',
        title2:'我的',
        title3:'我的等級:',
        text1:'我的質押',
        text2:'授權質押',
        text3:'贖回',
        text4:'我的|NAME|總數量',
        text5:'釋放',
        text6:'日分紅',
        text7:'日',
        text8:'已領取：',
        text9:'未領取：',
        text10:'領取|NAME|釋放',
        text11:'領取|NAME|日分紅',
        text12:'釋放50萬ULME速度:',
        text13:'已領取總釋放:',
        text14:'質押',
        text15:'質押數量:',
        text16:'1、質押數量為0.1MT',
        text17:'2、質押的MT可隨時贖回，手續費1%',
        text18:'請選擇',
        text19:'餘額',
        text20:'1、質押數量可選擇50,000ULME或100,000ULME或150,000ULME',
        text21:'2、質押的ULME可隨時贖回，手續費1%',
        text22:'授權',
        text23:'確認',
        text24:'取消',
        text25:'1、每個地址最多質押1MTG',
        text26:'2、質押的MTG可隨時無損贖回',
        text27:'3、質押在股東池的MTG激活後領取響應的權益分紅',
        text28:'提幣',
        text29:'提幣後會減少|NUM|算力,是否繼續?',
        text30:'我要提幣',
    },
    myFarmer:{
        title:'個人當前基礎條件與權益分紅狀態',
        text1:'我的等級：',
        text2:'我的農耕算力：',
        text3:'我的社區算力：',
        text4:'我的質押MTG ID：',
        text5:'無',
        text6:'質押',
        text7:'贖回',
        text8:'我的質押LP：',
        text9:'我的有效佈道：',
        text10:'個',
        text11:'待領取收益',
        text12:'MTG股東分紅（MT/ULME礦池提幣/佈道提取MT/算力激活MT）：',
        text13:'權益分紅（MT/ULME礦池提幣/佈道提取MT/算力激活MT）：',
        text14:'領取',
        text15:'您的等級與實際不附，可能影響您的算力，請點擊升級',
        text16:'升級',
    },
    vipRanking:{
        head1:'前31名V2 Farmer',
        head2:'前18名V3 Farmer',
        title1:'個人當前Farmer等級與業績排名狀態',
        title2:'全網當前社區算力排名前31名V2 Farmer',
        title3:'全網當前社區算力排名前18名V3 Farmer',
        text1:'我的等級：',
        text2:'我的社區算力：',
        text3:'排名',
        text4:'地址',
    },
    weekRanking:{
        head1:'社區節點',
        head2:'超級佈道者',
        title1:'個人當前基礎條件與權益分紅狀態',
        title2:'待領取收益',
        title3:'社區節點全網業績當前社區算力排名',
        title4:'超級佈道者全網業績當前社區算力排名',
        text1:'我的等級：',
        text2:'我的社區算力：',
        text3:'MTG股東分紅（MT/ULME礦池提幣/佈道提取MT/算力激活MT）：',
        text4:'V2/V3權益分紅（MT/ULME礦池提幣/佈道提取MT/算力激活MT）：',
        text5:'領取',
        text6:'排名',
        text7:'Farmer等級',
        text8:'地址',
        text9:'當前社區算力'
    },
    pledgeList:{
        title:'質押託管池',
        title11:'質押池',
        text1:'份',
        text2:'託管收益',
        text3:'質押總計',
        text4:'質押進度剩餘',
        text5:'個人已質押',
        text6:'總收益',
        text7:'我的質押',
        text8:'立即查看',
        text9:'每份5000，請輸入份數',
        text10:'可用餘額',
        text11:'授權數量',
        text12:'開始質押',
        text13:'每份1，請輸入份數',
        text14:'質押進度',

    },
    pledgeMine:{
        title:'我的質押',
        text1:'質押量',
        text2:'託管收益',
        text3:'贖回',
        text4:'已贖回',
    },
    Incentive:{
        title:{
            title:'50%-MT激励基金池',
            content:'全网产出MT领取的5％---当日全网新增390T农耕算力数量≥35单时，激励池50%的资金做循环激励分配（每天24小时结算）。'
        },
        nav0:{
            title:'20%-大单农耕排名奖',
            content:'20%给予当天大单农耕排名激励前4名------参与排名的大单地址当日新增390T农耕算力数量≥5单时，激励池资金做循环分配。第1名分8%、第2名分6%、第3名分4%、第4名分2%，金额相同的按激活农耕算力的时间排序。'
        },
        nav1:{
            title:'30%-邀请农耕排名奖',
            content:'30% 给予当天邀请390T农耕总单数排名激励前10名--------参与排名的邀请地址当日新增邀请390T农耕算力数量≥5单时，激励池资金做循环分配。第1名分12%，第2-4名均分9%，第5-10名均分9%。'
        },
    },
    Compensate:{
        title:{
            title:'5%-MT补偿基金池',
            content:'全网产出MT领取的5％---24小时之内全网没有新生农耕算力产生时，奖励最后101名---只要有农耕算力产生，就重新从24小时开始倒计时。\n最后一名领取资金池的40%，倒数第2-101名领取资金池的60%（ 第2-11名均分25%，第12-41名均分30%，第42-101名均分45%）。'
        },
    }
}
