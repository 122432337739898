<template>
  <div class="notice flex_center">
    <div class="mask"></div>
    <div class="notice_box" :style="$setWidth=='100%'?'':'width:400px;'">
      <div class="notice_box2">
        <div class="notice_title y_center" v-if="head&&head!=''">{{head}}</div>
        <div class="notice_title2" v-if="title&&title!=''">{{title}}</div>
        <div class="notice_content" v-html="zifu(content)"></div>
        <div class="notice_btn flex_center hand" @click="close">确定</div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  props:['title','content','head'],
  name: 'UpLevelData',
  data () {
    return {
    }
  },
  computed: {
    upLevel(){
      return this.$store.state.user.levelconfig
    }
  },
  mounted () {
    
  },
  methods: {
    zifu(value){
      if(value!=null){
        value = value.replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'")
            .replace(/&nbsp;/g, ' ')
        return value
      }else{
        return value
      }
    },
    close(){
      this.$emit('close')
    }
  }
}
</script>
 
<style lang="scss" scoped>
  .notice{
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .notice_box{
      position: relative;
      z-index: 3;
      width: 80%;
      background: rgba(#00BABC,0.5);
      border-radius: 10px;
      padding: 10px;
      color: #00b5d1;
      .notice_box2{
        background: rgba(#000000,0.7);
        border-radius: 10px;
        padding: 10px;
        .notice_title{
          font-size: 1.1rem;
          color: #00DEFF;
          margin-bottom: 10px;
        }
        .notice_title2{
          font-size: 0.95rem;
          color: #00DEFF;
          margin-bottom: 10px;
        }
        .notice_content{
          font-size: 0.9rem;
          max-height: 240px;
          overflow: auto;
          white-space: pre-line;
        }
        .notice_btn{
          width:100%;
          height: 40px;
          background: #00a5a8;
          color: #000000;
          font-size: 1rem;
          border-radius: 5px;
          margin-top: 10px;
        }
      }
    }
  }
</style>