var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_show),expression:"is_show"}],staticClass:"dialog-tips flex_center",class:{ 
        open: _vm.visible,
        close: !_vm.visible,
        dialogTop:_vm.position=='' || _vm.position=='top',
        dialogCenter:_vm.position=='center',
        dialogBottom:_vm.position=='bottom',
    }},[_c('div',{staticClass:"box",class:{
            message:_vm.type=='',
            error:_vm.type=='error',
            success:_vm.type=='success',
        }},[_vm._v(_vm._s(_vm.message))])])}
var staticRenderFns = []

export { render, staticRenderFns }