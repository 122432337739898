import Vue from "vue"; // 引入 Vue 是因为要用到 Vue.extend() 这个方法
import ToastComponent from "./toast.vue"; // 引入刚才的 toast 组件
 
let ToastConstructor = Vue.extend(ToastComponent);
let instance;
let show = false
const Toast = function(data, duration = 1500) {
    if(show == false){
        show = true
        instance = new ToastConstructor().$mount(); // 渲染组件
        document.body.appendChild(instance.$el); // 挂载到 body 下
        if(typeof data == 'string'){
            instance.message = data;
        }else if(typeof data == 'object'){
            instance.message = data.title;
            if(data.type!=undefined){
                instance.type = data.type
            }
            if(data.position!=undefined){
                instance.position = data.position
            }
        }
        instance.visible = true;
        instance.is_show = true;
        setTimeout(() => {
            instance.visible = false;
            setTimeout(() => {
                instance.is_show = false;
                show = false
            }, 300);
        }, duration);
    }
};
 
export default Toast;