const Web3=require("web3")

const prepareConfig={
    multicallAddress: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrl: "https://rpc-bsc.bnb48.club",
}
var INSIDE_EVERY_PARENTHESES = /\(.*?\)/g;
var FIRST_CLOSING_PARENTHESES = /^[^)]*\)/;
var AGGREGATE_SELECTOR = '0x252dba42';

var abiCoder = require('ethers/utils/abi-coder');
var hash = require('ethers/utils/hash');

function encodeParameters(types, vals) {
    return abiCoder.defaultAbiCoder.encode(types, vals);
}
function decodeParameters(types, vals) {
    return abiCoder.defaultAbiCoder.decode(types, '0x' + vals.replace(/0x/i, ''));
}
function strip0x(str) {
    return str.replace(/^0x/, '');
  }

function _makeMulticallData(calls) {
    var values = [calls.map(function (_ref) {
      var target = _ref.target,
          method = _ref.method,
          args = _ref.args,
          returnTypes = _ref.returnTypes;
      return [target, hash.id(method).substr(0, 10) + (args && args.length > 0 ? strip0x(encodeParameters(args.map(function (a) {
        return a[1];
      }), args.map(function (a) {
        return a[0];
      }))) : '')];
    })];
    var calldata = encodeParameters([{
      components: [{
        type: 'address'
      }, {
        type: 'bytes'
      }],
      name: 'data',
      type: 'tuple[]'
    }], values);
    return calldata;
  }
  async function aggregate(calls, config) {
    config=config||prepareConfig
      
    calls = calls.map(function (_ref4) {
        var call = _ref4.call,
            target = _ref4.target,
            returns = _ref4.returns;
        if (!target) target = config.multicallAddress;
        var method = call[0],
            argValues = call.slice(1);
    
        var _method$match$map = method.match(INSIDE_EVERY_PARENTHESES).map(function (match) {
          return match.slice(1, -1);
        }),
            argTypesString = _method$match$map[0],
            returnTypesString = _method$match$map[1];
        var argTypes = argTypesString.split(',').filter(function (e) {
          return !!e;
        });
        var args = argValues.map(function (argValue, idx) {
          return [argValue, argTypes[idx]];
        });

        var returnTypes = !!returnTypesString ? returnTypesString.split(',') : [];
        return {
          method: method.match(FIRST_CLOSING_PARENTHESES)[0],
          args: args,
          returnTypes: returnTypes,
          target: target,
          returns: returns
        };
      });
    //   console.log(calls)

      var returnTypeArray = calls.map(function (_ref5) {
        var returnTypes = _ref5.returnTypes;
        return returnTypes;
      }).reduce(function (acc, ele) {
        return acc.concat(ele);
      }, []);
      var returnDataMeta = calls.map(function (_ref6) {
        var returns = _ref6.returns;
        return returns;
      }).reduce(function (acc, ele) {
        return acc.concat(ele);
      }, []);

      var calldata=_makeMulticallData(calls)
      if(config.web3==undefined){
        config.web3=new Web3(config.rpcUrl);
      }
  return new Promise(async function (resolve, reject) {
    try{
      config.web3.eth.call({
        to: config.multicallAddress,
        data: AGGREGATE_SELECTOR+strip0x(calldata)
      }).then(e=>{
        try{
            var outerResultsDecoded = decodeParameters(['uint256', 'bytes[]'], e);
            var blockNumber = outerResultsDecoded.shift();
            var parsedVals = outerResultsDecoded.reduce(function (acc, r) {
                r.forEach(function (results, idx) {
                var types = calls[idx].returnTypes;
                var resultsDecoded = decodeParameters(types, results);
                acc.push.apply(acc, resultsDecoded.map(function (r, idx) {
                    if (types[idx] === 'bool') return r.toString() === 'true';
                    return r;
                }));
                });
                return acc;
            }, []);
    
            const data=[]
            for (var i = 0; i < parsedVals.length; i++) {
                var _returnDataMeta$i = returnDataMeta[i],
                    name = _returnDataMeta$i[0],
                    transform = _returnDataMeta$i[1];
                    
                data.push({
                    type:name,
                    value:transform !== undefined ? transform(parsedVals[i]) : parsedVals[i],
                    args:calls[i]&&calls[i].args.map(function (a) {
                        return a[0];
                      })
                })
            }
            resolve(data)
        }catch(e){
            reject(e)
        }

      },e=>{
        reject(e)
      })
    }catch(e){
      reject(e)
    }
    })

  }


  exports.aggregate = aggregate;

