import axios from 'axios'
import {
  MessageBox,
  Message,
  Loading
} from 'element-ui'
import store from '@/store'
import {
  getToken
} from '@/utils/auth'
import Toast from '@/components/toast/toast'
let gb_loading
const loading_conf = {
  background: '#1515154d'
}
var base_url = window.location.protocol + '//' + process.env.VUE_APP_BASE_API
// console.log(base_url, process.env.VUE_APP_DEBUG)
// if (process.env.VUE_APP_DEBUG) {
//   base_url = process.env.VUE_APP_BASE_API
// }
// base_url = process.env.VUE_APP_BASE_API
// base_url = window.location.protocol + '//' + 'www.mtdaoculb.vip/'

// create an axios instance
const service = axios.create({
  baseURL: base_url, // url = base url + request url
  // baseURL: '/api/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // jwt
      config.headers['passport'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    gb_loading&&gb_loading.close()
    /** 验证 token */
    if (res.status === 2) {
      MessageBox.confirm(res.msg,
        'Confirm logout', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        store.dispatch('admin/delete_token').then(() => {
          let cs=this.$store.state.user.model=='shop'?'a=&':''
          this.$router.push(`/login?${cs}redirect=${this.$route.fullPath}`);
        })
      })
    }else if (res.status === 3) {
      MessageBox.confirm(res.msg, '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
    }
    /** 业务逻辑未通过 */
    if (res.status==undefined||res.status === 0) {
    /** 业务逻辑未通过 */
      Toast({
        title:res.msg,
        type:'error',
      })
      // Message({
      //   message: res.msg,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return Promise.reject(res)
    } else if (res.status === 1) {
      /** 通过返回数据 */
      return res.data
    }
  },
  error => {
    gb_loading&&gb_loading.close()
    // console.log('err' + error) // for debug
    // Message({
    //   message: error.msg,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    Toast({
      title:error.msg,
      type:'error',
    })
    return Promise.reject(error)
  }
)

export function  getTimestamp(now=null)
{
    now=now||(new Date())
    var y = now.getFullYear();
    var m = now.getMonth() + 1;
    var d = now.getDate();
    var hour = now.getHours();
    var min = now.getMinutes();
    var sec = now.getSeconds();
    var msec = now.getMilliseconds();
    return "[" + y + "-" + (m < 10 ? "0" + m : m) + 
            "-" + (d < 10 ? "0" + d : d) + 
            " " + (hour < 10 ? "0" + hour : hour) + 
            ":" + (min < 10 ? "0" + min : min) + 
            ":" + (sec < 10 ? "0" + sec : sec) + 
            "." + (msec < 100 ? (msec < 10 ? "00" + msec : '0' + msec) : msec) + "] ";
}

export function get(url, _data = {}, method = 'POST',isLoading=false) {
  if(!isLoading){
    gb_loading = Loading.service(loading_conf)
  }
  let params; let data = {}
  if (method === 'get') {
    params = _data
  } else {
    data = _data
  }
  return service({
    url: url,
    method: method,
    params: params,
    data: data
  })
}

export default service
