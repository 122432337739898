<template>
  <div>
    <div class="head flex_lr">
      <div class="flex_row" style="width: 10%;">
        <i class="ifont icone-fanhui hand" @click="back()"></i>
      </div>
      <div class="flex_row">{{title}}</div>
      <div class="flex_lr" style="width: 10%;"></div>
    </div>
    <div style="height:50px;"></div>
  </div>
</template>
 
<script>
export default {
  props:['title'],
  name: 'myhead',
  data () {
    return {
      
    }
  },
  computed: {
    
  },
  mounted () {

  },
  methods: {
    back(){
      this.$router.go(-1)
    }
  }
}
</script>
 
<style lang="scss" scoped>
.head{
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 50px;
  // background: linear-gradient(0deg, #C4C4C4, #FFFFFF, #C4C4C4);
  background: #01122A;
  padding: 0 5px;
  // color: #BF8507;
  color: #ffffff;
  font-weight: bold;
  font-size: 17px;
  i{
    // color: #BF8507;
    color: #ffffff;
    font-size: 30px;
    margin-right: 5px;
  }
}
</style>