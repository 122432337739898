import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}


/* Layout */
export const asyncRoutes = []
export const constantRoutes =[ 
  {
    path: "*",
    redirect: "/",
    hidden: true,
  },
  {
    path: "/", 
    name: "layout", 
    component: () => import("@/layout/index"),
    // redirect: "/index",
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import('@/views/index'),
      },
      {
        path: '/nft',
        name: 'NFT',
        component: () => import('@/views/nft'),
      },
      {
        path: '/nft_details/:id',
        name: 'NFT详情',
        component: () => import('@/views/nftDetails'),
      },
      {
        path: '/mt',
        name: 'MT',
        component: () => import('@/views/mt'),
      },
      {
        path: '/about_us',
        name: '关于我们',
        component: () => import('@/views/aboutUs'),
      },
      {
        path: '/planning',
        name: '发展规划',
        component: () => import('@/views/planning'),
      },
      {
        path: '/LP',
        name: 'LP流动池',
        component: () => import('@/views/LP'),
      },
      {
        path: '/miner',
        name: '矿池',
        component: () => import('@/views/miner'),
      },
      {
        path: '/nftInfo',
        name: 'NFT置压',
        component: () => import('@/views/nftInfo'),
      },
      {
        path: '/id_level',
        name: '身份级别',
        component: () => import('@/views/idLevel'),
      },
      {
        path: '/Receiving_details',
        name: '领取明细',
        component: () => import('@/views/receivingDetails'),
      },
      {
        path: '/vote_list',
        name: '投票',
        component: () => import('@/views/voteList'),
      },
      {
        path: '/pledge_list',
        name: '质押托管',
        component: () => import('@/views/pledgeList'),
      },
      {
        path: '/pledge_mt',
        name: 'MT质押',
        component: () => import('@/views/pledgeMt'),
      },
      {
        path: '/miner_MT',
        name: 'miner_MT',
        component: () => import('@/views/miner_MT'),
      },
      {
        path: '/Incentive',
        name: 'Incentive',
        component: () => import('@/views/Incentive'),
      },
      {
        path: '/IncentiveLog',
        name: 'Incentive',
        component: () => import('@/views/IncentiveLog'),
      },
      {
        path: '/Compensate',
        name: 'Incentive',
        component: () => import('@/views/Compensate'),
      },
    ]
  },
  { 
    path: "/help", 
    name: '新手帮助',
    component: () => import('@/views/help'),
  },
  {
    path: '/myCommunity',
    name: '我的社区',
    component: () => import('@/views/myCommunity'),
  },
  {
    path: '/setUp',
    name: '设置',
    component: () => import('@/views/setUp'),
  },
  { 
    path: "/notice", 
    name: '公告',
    component: () => import('@/views/notice'),
  },
  { 
    path: "/share/:wallet", 
    name: '邀请分享',
    component: () => import('@/views/share'),
  },
  {
    path: '/Genesis_node',
    name: '创世节点',
    component: () => import('@/views/genesisNode'),
  },
  {
    path: '/My_Farmer',
    name: 'Defi Farmer',
    component: () => import('@/views/myFarmer'),
  },
  {
    path: '/Vip_ranking',
    name: 'vip排行',
    component: () => import('@/views/vipRanking'),
  },
  {
    path: '/Week_ranking',
    name: '周榜',
    component: () => import('@/views/weekRanking'),
  },
  {
    path: '/vote_details',
    name: '投票详情',
    component: () => import('@/views/voteDetails'),
  },
  {
    path: '/pledge_mine',
    name: '我的质押',
    component: () => import('@/views/pledgeMine'),
  },
]

const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
